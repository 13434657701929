
.sizeInfoMessage {
  display: flex;
  align-items: center;
  padding: theme('space.3');
  margin: theme('space.2') 0;
  font-size: 13px;
  line-height: 17px;
  background-color: theme('colors.gray50');

  & > div {
    margin-right: theme('space.2');
  }
}

.markdown {
  p {
    margin: 0;
  }

  a:link, a:visited {
    text-decoration: underline;
  }
}
