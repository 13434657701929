.section {
  margin-top: theme('spacing.5');
  padding: theme('spacing.8') theme('spacing.10');
  background-color: theme('colors.white');

  @media (max-width: 1023px) {
    padding: theme('spacing.8') theme('spacing.3');
  }
}

.title {
  display: block;
  margin-bottom: theme('spacing.6');
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
