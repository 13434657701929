.tabs {
  display: flex;
  gap: theme('spacing.10');
  padding: 0 theme('spacing.5');

  @media screen and (min-width: 1024px) {
    padding: 0;
  }
}

.tabContent {
  margin-top: theme('spacing.8');
}

.tabGrid {
  min-height: auto;
}

.wrap-tab-modifier {
  padding: 0 !important;
}

.wrap-tab-modifier > div {
  padding: 0 !important;
  margin: 0 !important;
  min-height: auto !important;
}

.loadingIndicator {
  padding: 0 theme('spacing.5');
}
