.description {
  margin-top: 16px;
}

.subscriptionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.logo {
  max-width: 100px;
}
