.successMessage {
  margin-bottom: theme('space.3');
  padding: theme('space.3') theme('space.6');
  background-color: theme('colors.lightGrey');
  font-size: 12px;
}

.paymentError {
  color: theme('colors.danger');
  font-size: 12px;
  margin-bottom: theme('space.3');
}

.paymentMethodsWrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
}

.paymentInfoMessage {
  display: flex;
  align-items: center;
  padding: theme('space.4');
  margin: theme('space.5') 0;
  font-size: 13px;
  line-height: 17px;
  background-color: theme('colors.lightBlue');

  & > div {
    margin-right: theme('space.4');
    min-width: theme('space.7');
  }
}

.paymentInfoMessageHighlighted {
  animation: highlight 1.5s;
  box-shadow: 0 0 0 4px theme('colors.white');
}

@keyframes highlight {
  0% {
    box-shadow: 0 0 0 0 theme('colors.linkHover');
  }
}
