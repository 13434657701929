.alternativeProductWrap {
  position: absolute;
  right: calc(100% + 15px);
  background-color: theme('colors.white');
  width: 100%;
  min-width: calc(100% + 30px);
  padding: theme('space.4');

  &:after {
    content: "";
    position: absolute;
    right: -7px;
    top: 35px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: theme('colors.white');
    z-index: -1;
  }

  &.alternativeOutfitWrap {
    min-width: calc(100% + 310px);

    :global .slider__container {
      max-width: 50%;
    }
  }

  :global .slider__container {
    margin-top: calc(0px - theme('space.2'));

    .swiper.swiper-vertical {
      max-height: 432px;
      padding: theme('space.6') 0 !important;

      .swiper-slide:last-child {
        & > div {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        color: theme('colors.primary');
        top: theme('space.5');
        right: auto;
        left: 50%;
        width: 100%;
        height: theme('space.6');
        transform: translateX(-50%);
        background-color: theme('colors.white');

        &.swiper-button-disabled {
          display: none;
        }

        &:after {
          font-size: 20px;
          font-weight: bold;
          transform: rotate(90deg);
        }
      }

      .swiper-button-next {
        top: auto;
        bottom: 0;
      }
    }
  }
}

.alternativeOutfitImagesWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: theme('space.4');

  & > a {
    margin-top: calc(0px - theme('space.2'));
  }
}

.blockWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: theme('space.4');
}

.alternativeProductHeaderWrap {
  strong {
    font-size: 23px;
    margin-right: theme('space.3');
  }
}

.productWrap {
  padding: theme('space.1') 0;
  margin-right: 0;
  border-bottom: 1px solid theme('colors.gray100');

  img {
    min-width: 90px;
  }
}

.productInfo {
  justify-content: space-between;

  a {
    flex-grow: unset;
  }
}

.continueShoppingWrap {
  margin: theme('space.3') 0 0;
}
