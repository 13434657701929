.productFlags {
  color: theme('colors.white');
  pointer-events: none;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
  z-index: 1;

  a {
    pointer-events: auto;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}
