.outfitSliderWrap {
  display: flex;
  align-items: center;
  gap: theme('space.8');
  padding: theme('space.8') 0;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
}

.outfitMainLink {
  position: relative;
  max-width: 410px;
}

.WishlistButton {
  z-index: 1;
}

.sliderWrap {
  max-width: 100%;

  div[class^="product-box_wishlistWrapper"] {
    display: none;
  }

  @media (min-width: 1024px) {
    max-width: 65%;

    :global h3.uppercase {
      text-align: left;
    }
  }

  :global .slider__container {
    .swiper {
      padding-bottom: 0 !important;
    }
  }
}
