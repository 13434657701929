.imageSliderWrapper {
  :global .slider__container {
    .swiper {
      &-button {
        &-prev, &-next {
          color: theme('colors.fontColor');
          padding: theme('space.2');

          &:after {
            font-size: 22px;
          }
        }
      }
    }
  }
}
