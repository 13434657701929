.row {
  display: flex;
  column-gap: 12px;
}

.checkbox {
  border: 1px solid theme('colors.inputBackground');
  background-color: theme('colors.inputBackground');
  color: theme('colors.primary');
  box-shadow: none;
  border-radius: 0;
  width: 20px;
  height: 20px;
  outline: none;
  margin-top: 1px;
  cursor: pointer;
}

.checkbox:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.checkboxDisabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: theme('colors.gray300') !important;
  background-color: theme('colors.gray100') !important;
}

.hasHoverEffect {
  &:not(:checked):hover:before {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACISURBVHgBtdDRDUAwEAbgOwNghE6AVUzASEZgAjGBmEBHMIINzrVFpKK0if+p6d3X9goQGPRpTkWREtCollEAKghw+HTjFSFgty6yxhCk9iNV8EUacmFORD7bB7iQhgg0mYFpPPAbUkHTmLX8UxUvJTeWjHoXOqGF98IzuiUWWcPzEj+1hb+yAXODSJq4FOkKAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.5;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &:not(:checked):focus:before {
    display: none;
  }
}

.checkboxWhiteStyle {
  background-color: theme('colors.white');
  border: 1px solid theme('colors.primary');

  &:focus {
    border: 1px solid theme('colors.primary');
  }
}

.label {
  color: theme('colors.primary');
  cursor: pointer;
}

.label a:link,
.label a:visited {
  text-decoration: underline;
}

.errorMessage {
  color: theme('colors.danger');
  font-size: 13px;
  margin-left: 32px;
  font-weight: 400;
}

.order {
  order: 1;
}
