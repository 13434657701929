.outsideRow {
    position: relative;
}

.row {
    background: theme('colors.inputBackground');
    display: flex;
    position: relative;
    height: 52px;

    .inputBorder {
        position: relative;
        width: calc(100% / 3);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 22px;
            bottom: 6px;
            width: 1px;
            background: theme('colors.gray200');
            z-index: 2;
        }

        input {
            width: 100%;
        }
    }

    .inputBorderSecond {
        position: relative;
        width: calc(100% / 3);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 9px;
            bottom: 6px;
            width: 1px;
            background: theme('colors.gray200');
            z-index: 2;
        }

        input {
            width: 100%;
        }
    }

}

.infoIconWrap {
    transition: all 200ms ease-in-out;
    cursor: pointer;
    z-index: 2;
    margin: auto theme('spacing.2') auto theme('spacing.1');

    &:hover {
        opacity: 0.6;
    }
}

.birthdateInfoMarkdown {
    color: theme('colors.fontColor');
    font-size: 14px;

    & > * {
        margin: inherit !important;
    }
}

.label {
    color: theme('colors.fontColor');
    position: absolute;
    top: 50%;
    left: 12px;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    font-size: 11px;
    font-weight: 500;
    transform: translate(0, calc(-50% - 10px));
}

.row input {
    border: none;
    background: none;
    width: calc(100% / 3);
    text-align: center;
    font-size: 15px;
    height: 52px;
    padding-top: theme('space.6');
}

.row input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.errorMessage {
    color: theme('colors.danger');
    font-size: 13px;
    font-weight: 400;
}

.customInput {
    padding-top: theme('space.1') !important;
}
