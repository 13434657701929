.text {
  overflow: hidden;
}
.text > * {
  color: inherit;
}
.text * + h1 {
  margin-top: 8px;
}
.text * + h2 {
  margin-top: 8px;
}
.text * + h3 {
  margin-top: 8px;
}
.text * + h4 {
  margin-top: 8px;
}
.text * + h5 {
  margin-top: 8px;
}
.text * + h6 {
  margin-top: 8px;
}
.text * + p {
  margin-top: 8px;
}
.text * + ul {
  margin-top: 8px;
}
.text * + ol {
  margin-top: 8px;
}
