.buttonWrapper {
  display: flex;
  align-items: center;

  padding: theme('space.1') theme('space.5');
  color: theme('colors.gray500');
  font-weight: 400;
  font-size: 14px;

  .button {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;

    .buttonLabel {
      text-transform: uppercase;
    }
  }
}
