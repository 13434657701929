.minWidthBlock {
  margin-bottom: theme('spacing.4');
  padding-bottom: theme('spacing.4');
  position: relative;
}

.minWidthBlock:after {
  bottom: 0;
  content: "";
  position: absolute;
  height: 1px;
  background-color: theme('colors.gray100');
  left: 0;
  right: 0;
}

.minWidthBlock--last:after {
  content: none;
}

.name {
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: theme('spacing.3');
}

.amountPriceRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.price {
  font-weight: 600;
}

.imageWrap {
  display: flex;
  justify-content: center;
  width: 80px;
  margin-right: theme('spacing.4');
}

.imageWrap--placeholder {
  margin-top: -8px;
  margin-right: 0;
}

.itemRow {
  display: flex;
  font-size: 14px;
  line-height: 18px;
  width: 100%;

  span {
    display: block;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    gap: 16px;
    padding: theme('spacing.3') 0;
    border-bottom: 1px dotted theme('colors.gray200');
  }
}

.contentWrap {
  flex-grow: 1;
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: theme('spacing.6');
  margin-top: theme('spacing.6');

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}