.ctaSectionWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: theme('space.2') 0;
  margin: theme('space.3') 0;

  &.hasBorder {
    border-bottom: 1px dotted theme('colors.lightGrey');
  }

  .ctaBtn {
    width: 100%;
    max-width: 400px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;

    button {
      font-size: 14px;

      &:last-child {
        max-width: 100%;
      }
    }
  }
}

.continueShopping {
  color: theme('colors.gray400');
  padding: theme('space.3') 0;

  svg {
    transform: rotate(180deg);
    color: inherit;
  }

  @media (max-width: 767px) {
    order: 2;
  }
}

.orderOverviewHeadlines {
  display: flex;
  justify-content: space-between;
  gap: theme('space.4');
  font-size: 14px;
  color: theme('colors.fontColor');
  margin: theme('space.3') theme('space.3') 0;
  padding-bottom: theme('space.3');
  border-bottom: 1px dotted theme('colors.lightGrey');

  @media (max-width: 767px) {
    display: none;
  }
}

.productOverviewInfoWrap {
  display: flex;
  gap: theme('space.4');
  width: 80%;

  & > div:last-child {
    text-align: end;
  }

  @media (max-width: 767px) {
    width: 50%;
    flex-direction: column;
    gap: theme('space.2');
    font-size: 12px;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.checkoutProductOverviewInfoWrap {
  @media (max-width: 767px) {
    width: 60%;
  }
}

.lineItemsWrap {
  min-height: 200px;
  border-bottom: 1px dotted theme('colors.lightGrey');

  li:last-child {
    & > div {
      border-bottom: 0 none;
    }
  }
}

.voucherWrap {
  padding: theme('space.5') 0;
  margin-bottom: theme('space.8');
  border-bottom: 1px dotted theme('colors.lightGrey');
}

.orderSummaryWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div,
  & > section {
    width: calc(100% / 3);
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: theme('space.8');

    & > div,
    & > section {
      width: 100%;
    }
  }
}

.shippingWrapper {
  font-size: 14px;

  span {
    display: block;
  }
}

.shippingHeadline {
  font-weight: 700;
}

.shippingInfo {
  @media (min-width: 1024px) {
    margin-top: theme('spacing.4');
  }
}

.tsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tsIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  img {
    margin-right: theme('space.2');

    @media (max-width: 1023px) {
      width: 40px;
    }
  }

  span {
    text-align: left;
    margin-right: theme('space.3');
    line-height: 1;
  }
}

.emptyCart {
  padding: theme('space.5') theme('space.3');
  color: theme('colors.danger');
}

.cartTitle {
  margin-bottom: theme('space.6');
}
