.shopUspContainer {
  display: block;
  width: 20%;
}

.shopUspImg {
  display: block;
  margin: 0 auto;
}

.shopUspHeadline {
  color: theme('colors.fontColor');
  font-size: 13px;
  display: block;
  margin-top: 10px;
  text-align: center;
}

.shopUspText {
  color: theme('colors.gray200');
  font-size: 13px;
  display: block;
  text-align: center;
}
