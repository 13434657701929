.productFlag {
  color: theme('colors.white');
  padding: theme('space.1');
  max-width: 48px;

  &.campaignFlagImage {
    padding: 0;
    text-align: left;

    img {
      aspect-ratio: initial !important;
      width: auto !important;
      display: inline-block;
    }
  }
}
