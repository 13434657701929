.productBox {
  color: theme('colors.fontColor');
  font-size: 14px;
  line-height: 14px;
  position: relative;

  &.enGermanyProductBox {
    .productBoxInfoName {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 19px;
    }
  }

  @media (max-width: 767px) {
    font-size: 12px;
    border: 1px solid theme('colors.gray100');
    border-right-width: 0;
    border-bottom-width: 0;

    &.enGermanyProductBox {
      border: 0 none;
      background-color: theme('colors.gray50');

      img {
        margin: auto;
      }
    }
  }
}

.wishlistWrapper {
  z-index: 2;
}

.productBoxLinkWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.productBoxWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.productBoxFlags {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  row-gap: theme('space.2');
}

.productBoxInfo {
  position: relative;
  padding: theme('space.5') theme('space.2') 0;

  @media (max-width: 767px) {
    padding: theme('space.5');
    text-align: center;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    gap: theme('space.2');

    &:first-child {
      color: theme('colors.gray400');
      margin-bottom: theme('space.1');
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      gap: theme('space.1');
    }
  }

  &.enGermanyProductBoxInfo {
    padding: 0;
    background-color: theme('colors.white');

    @media (min-width: 768px) {
      background-color: unset;
    }

    & > div {
      flex-direction: row;
      text-align: left;
      padding: theme('space.4') 0;

      @media (min-width: 768px) {
        padding: theme('space.4');
      }

      &:not(:last-child) {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: theme('colors.white');
        z-index: 2;
        transform: translateY(0);
        transition: transform 0.2s ease-in-out;
      }

      &:first-child {
        display: none;
      }

      & > div {
        flex-basis: 50%;
        max-width: 50%;
        padding-top: theme('space.4');

        &:first-child:not(div[class^="product-box-hover_hovering"]) {
          border-top: 3px solid theme('colors.enGermanyBlue') !important;
        }

        &, div {
          font-size: 16px;
          line-height: 17px;
          font-family: theme('fontFamily.theSerif');

          &:not(div[class^="price_newPrice"]) {
            color: theme('colors.enGermanyPrimary');
          }
        }
      }
    }
  }

  &.enGermanyProductBoxInfoHover {
    & > div:not(:last-child) {
      transform: translateY(-100%);
    }

    & > div:last-child > div {
      opacity: 1;
    }
  }
}

.infoLeftSide {
  font-size: theme('fontSize.xs');
  flex-basis: 60%;
  max-width: 60%;

  @media (max-width: 767px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.infoRightSide {
  flex-basis: 40%;
  max-width: 40%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.productBoxInfoArtNum {
  font-weight: 500;

  @media (max-width: 767px) {
    display: none;
  }
}

.productBoxInfoName {
  font-size: theme('fontSize.sm');
  font-weight: 300;
}

.topSellerDesigner {
  font-weight: normal;
  text-align: center;
}

.productBoxInfoPrice {
  font-size: theme('fontSize.sm');
  margin: 0;
  gap: theme('space.2');

  div[class^="price_oldPrice"] {
    font-size: theme('fontSize.sm');
  }
}

.columnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
