.headline {
  margin: theme('space.7') 0;
  font-size: 19px;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 27px;
  }
}

.subHeading {
  padding: theme('space.6');
  font-size: 14px;
}

.resetForm {
  padding: theme('space.3') theme('space.6') theme('space.6');
  font-size: 13px;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .inputLabel {
    margin-top: theme('space.3');
    align-self: flex-start;
  }

  .inputInvalid {
    color: theme('colors.danger');
  }

  .inputField {
    padding: 0 theme('space.2');
    min-height: 28px;
    font-size: 13px;
    width: 100%;
  }

  .inputInvalidField {
    border-color: theme('colors.danger');
    border-width: 2px;
    background-color: theme('colors.lightRed');
  }

  .inputInfoText {
    font-size: 12px;

    > span {
      margin-right: theme('space.1');
    }
  }

  .validationStatus {
    margin: theme('space.3') 0;

    > li {
      font-size: 12px;

      .icon {
        padding: 0;
        margin-right: theme('space.1');
        background-color: theme('colors.inputBackground');
      }

      .successIcon {
        color: theme('colors.success');
      }

      .failedIcon {
        color: theme('colors.danger');
      }
    }
  }

  .ctaButton {
    text-transform: uppercase;
    font-size: 125%;
    width: 100%;
  }

  @media (min-width: 768px) {
    padding: theme('space.6');
    display: inline-grid;
    grid-auto-rows: max-content;
    grid-gap: theme('space.3') theme('space.19');
    grid-template-columns: max-content 1fr;
    width: 50%;

    .inputLabel {
      align-self: center;
      margin-top: 0;
    }

    .inputInfoText {
      grid-column-start: 2;
      margin-top: -13px;
    }

    .validationStatus {
      grid-column: 1 / span 2;
      margin: 0;
    }

    .ctaButton {
      grid-column-start: 2;
    }
  }
}
