.benefitsHeadline {
  text-transform: uppercase;
  margin-bottom: theme('space.3');
}

.benefitsWrap {
  margin-left: theme('space.4');
  font-size: 14px;
}

.benefit {
  display: flex;
  align-items: center;
  gap: theme('space.2');
  padding-top: theme('space.1');
}
