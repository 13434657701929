.wishlist {
  .markdown {
    text-align: center;
  }

  .item {
    display: flex;

    &:not(:last-child) {
      padding-bottom: theme('space.3');
      margin-bottom: theme('space.3');
      border-bottom: 1px dotted #dadada;
    }

    .image {
      padding: 0 theme('space.1');
      flex: 0 0 85px;
    }

    .details {
      padding: 0 theme('space.1');
      flex: auto;
      font-size: 13px;

      a {
        text-decoration: none;
      }

      .designerName {
        color: theme('colors.gray400');
      }

      .itemName {
        font-weight: 700;
      }

      .price > div {
        font-size: 13px;
        font-weight: 700;
      }
    }

    .buttons {
      padding: 0 theme('space.1');
      flex: 0 0 42px;

      .removeFromWishlist,
      .addToBasket {
        cursor: pointer;
        margin-bottom: theme('space.2');
        padding: theme('space.1') theme('space.3');
      }

      .addToBasket {
        transform: translateX(2px);
      }
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-y: auto;

  .wishlistPopupWrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .popup {
    top: theme('space.7');
    position: relative;
    max-width: 900px;
    z-index: 1051;
    margin: 0 auto;
    padding-left: theme('space.7');

    @media screen and (min-width: 1024px) {
      padding: 0;
    }

    .close {
      position: absolute;
      right: 1px;
      width: theme('space.7');
      height: 30px;
      cursor: pointer;
      border: 1px solid theme('colors.primary');
      border-left-width: 0;
      background: theme('colors.white');
      text-align: center;
      vertical-align: middle;
      line-height: 30px;
      padding: 1px 1px 1px 0;

      .text {
        position: absolute;
        top: 0;
        right: 100%;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        padding-right: theme('space.2');
      }
    }

    .body {
      background: theme('colors.white');
      border: 1px solid theme('colors.primary');
      min-height: 350px;
      margin-right: theme('space.7');
      box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
      padding: theme('space.4');
      display: flex;
      gap: theme('space.5');
      flex-direction: column;

      @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: theme('space.8');
      }

      .productImage,
      .productInfo {
        flex: 1;
        max-width: 100%;

        @media screen and (min-width: 1024px) {
          max-width: calc(50% - theme('space.4'));
        }
      }

      .productImage {
        padding-top: theme('space.4');

        @media screen and (min-width: 1024px) {
          padding-top: 0;
        }

        :global .slider__container {
          $thumb-width: 50px;

          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f3f1;
            user-select: none;

            &, img {
              @media screen and (min-width: 1024px) {
                aspect-ratio: 3 / 4;
              }
            }
          }

          .swiper {
            &:not(.swiper-thumbs) {
              padding-bottom: 10px !important;
            }

            &-button {
              &-prev, &-next {
                opacity: 1;
                width: 20px;
                --swiper-navigation-size: #{$thumb-width * 4 / 3};
                background: hsla(0, 0%, 100%, 0.85);

                &::after {
                  font-size: 16px;
                  color: theme('colors.black');
                }
              }

              &-prev {
                left: 0;
              }

              &-next {
                right: 0;
              }

              &-disabled {
                &, &::after {
                  opacity: 0;
                }
              }
            }
          }

          &--with-thumbs {
            display: initial;

            .slider__slides {
              padding: 0;
            }

            .slider__thumbs {
              height: unset;
              width: unset;

              @media screen and (max-width: 1023px) {
                display: none;
              }

              .swiper-slide {
                width: $thumb-width !important;
                height: auto !important;
                border-bottom: 2px solid transparent;
                cursor: pointer;

                &.swiper-slide-thumb-active {
                  border-color: theme('colors.primary');
                }

                & > div {
                  pointer-events: none;
                }
              }
            }
          }
        }
      }

      .productInfo {
        @media screen and (min-width: 1024px) {
          padding-top: theme('space.7');
        }

        h3 {
          font-size: 17px;
          font-weight: 200;
          line-height: 23px;
        }

        .productNumber {
          font-size: 13px;
          line-height: 17px;
          margin-top: theme('space.2');
        }

        .sizesModal {
          margin-top: theme('space.10');
          height: 32px; // fixed due to pdpSizeModal opened expanding the entire popup
        }

        .selectedSizeError {
          font-size: 14px;
          color: theme('colors.danger');
          margin-top: theme('space.2');
        }

        .sizeInformation {
          float: right;
          font-size: 13px;
          margin-top: theme('space.2');

          a {
            text-decoration: none;
          }
        }

        .deliveryTime {
          font-size: 12px;
        }

        .popupPrice {
          align-items: baseline;
          margin-top: theme('space.10');
          margin-bottom: theme('space.4');

          & > div {
            font-size: 16px;
            line-height: 16px;
            font-weight: 700;
          }

          :last-child {
            font-size: 23px;
            line-height: 23px;
          }
        }

        .priceDeliveryHint {
          position: relative;
          width: fit-content;
          font-size: 10px;
          color: theme('colors.gray400');

          a {
            text-decoration: underline;
          }

          * {
            margin: 0;
            line-height: 20px;
          }
        }

        .shippingCostsTooltip {
          position: absolute;
          bottom: 170%;
          right: -30px;
          background-color: theme('colors.white');

          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -7px;
            width: 14px;
            height: 14px;
            background-color: theme('colors.white');
            transform: translateX(-50%) rotate(45deg);
            z-index: 11;
          }

          &:after {
            box-shadow: 0 0 5px rgba(15, 32, 47, .5);
            z-index: 9;
          }
        }

        .shippingCostsTooltipText {
          position: relative;
          padding: theme('space.3');
          background-color: theme('colors.white');
          box-shadow: 0 0 5px rgba(15, 32, 47, .5);
          font-size: 12px;
          text-align: center;
          z-index: 10;
        }

        .moreDetails, .notBuyableText {
          font-size: 12px;
        }

        .addToBasketButton {
          width: fit-content;
          margin-top: theme('space.9');
          text-transform: uppercase;
        }
      }
    }
  }
}
