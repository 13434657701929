.productColors {
  padding-bottom: theme('space.5');
  margin-bottom: theme('space.5');
  font-size: 12px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.colorName {
  display: flex;
  gap: theme('space.1');
  margin-bottom: theme('space.1');
}

.colorVariantsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: theme('space.1');
}

.colorVariant {
  a:hover {
    opacity: 0.75;
  }
}

.activeColorVariant {
  border: 1px solid theme('colors.primary');
}
