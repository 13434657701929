.navigationWrapper {
    position: relative;
    height: 100%;
    overflow: hidden auto;
}

.navigationFirstLevel {
    max-height: 100%;
    padding: theme('space.4') theme('space.4') 0;
    background-color: theme('colors.white');
    transition: all 300ms ease-in-out;
    overflow-y: auto;

    .navigationItem {
        font-weight: bold;

        &:last-of-type {
            border-bottom: 0 none;
        }
    }
}

.navigationSecondThirdLevel {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    padding: theme('space.4') theme('space.4') 0;
    transition: all 300ms ease-in-out;
    background-color: theme('colors.white');

    .navigationItem:first-of-type {
        border-top: 1px solid theme('colors.gray100');
    }
}

.isActive {
    left: 0;
    transition: all 200ms ease-in-out;
}

.isHidden {
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;
}

.navigationItem {
    padding: theme('space.3') 0;
    border-bottom: 1px solid theme('colors.gray100');

    button, a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.navigationHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: theme('space.3') 0 theme('space.4');
    margin-bottom: theme('space.2');

    button {
        position: absolute;
        left: theme('space.4');
        width: 50px;
    }
}

.navigationBackBtn {
    width: 100%;
    position: relative;
    text-align: center;
    padding: theme('space.4') 0;
    color: theme('colors.gray500');
}

.navigationBackIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: theme('colors.gray50');
    padding: theme('space.2');
    border-radius: 50%;
    color: theme('colors.fontColor');
}

.hasBorderTop {
    border-top: 1px solid theme('colors.gray100');
}
