.result {
  font-size: 12px;
  padding-top: theme('spacing.4');
  border-top: 1px solid theme('colors.darkBlue');

  &:first-of-type {
    border-top: 0;
    padding-top: 0;
  }

  @media (min-width: 768px) {
    flex-basis: calc(50% - theme('spacing.8'));

    &:nth-child(-n + 2) {
      border-top: 0;
      padding-top: 0;
    }
  }
}

.sizes {
  display: flex;
  flex-wrap: wrap;
  gap: theme('spacing.2');
  margin-top: theme('spacing.1');
}

.storeInfo {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: theme('spacing.4');
}

.mainStoreInfo {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  margin-bottom: theme('space.4');

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.phone {
  margin-top: theme('spacing.4');
}

.isClosed {
  color: theme('colors.danger');
}

.openingHours {
  flex-basis: 40%;
}

.appointmentButton {
  background-color: theme('colors.storeavailability.appointment');
}

.showLocation {
  margin-top: theme('spacing.4');
  text-decoration: underline;
  text-transform: uppercase;
}
