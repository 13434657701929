.priceWrap {
  display: flex;
  gap: theme('space.3');
  font-size: theme('fontSize.2xl');
  color: theme('colors.fontColor');
  margin: theme('space.2') 0;
}

.isBold {
  font-weight: 700 !important;

  .oldPrice {
    font-weight: 500;
  }
}

.oldPrice {
  font-size: 16px;
  color: theme('colors.gray300');
  text-decoration: line-through;
}

.newPrice {
  color: theme('colors.discountedPrice');
}

.giftcardLabel {
  font-weight: normal;
  color: theme('colors.darkBlue');
}
