.newsletterResubscriptionGroup {
  &,
  .selectionGroup {
    &:not(:last-child) {
      margin-bottom: theme('space[1.5]');
    }
  }

  @media screen and (max-width: 767px) {
    &:first-child {
      margin-top: theme('space[1.5]');
    }
  }

  .selectionGroup {
    label {
      cursor: pointer;
    }

    .selectionMailingGroup {
      display: none;

      & + label {
        display: inline-block;
        position: relative;
        padding-left: theme('spacing.7');

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50%;
        }

        &:before {
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          margin-right: theme('spacing.1');
          border: 1px solid theme('colors.gray200');
          background: theme('colors.white');
        }

        &:after {
          top: 3px;
          left: 3px;
          width: 14px;
          height: 14px;
          background: theme('colors.primary');
          opacity: 0;
          transform: scale(0, 0);
          transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
        }
      }

      &:checked + label:after {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  }

  .mailingListOptions {
    margin-top: theme('space.3');
  }
}
