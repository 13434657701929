.infobox {
  position: relative;
  margin-top: theme('spacing.4');
  margin-left: 0;
  padding: theme('spacing.6') theme('spacing.6') theme('spacing.6') theme('spacing.8');
  background: theme('colors.greenNeon');
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &:before {
    content: 'i';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    background-color: theme('colors.white');
    color: theme('colors.greenNeon');
    border: 2px solid theme('colors.greenNeon');
    border-radius: 50%;
  }
}
.infobox--os {
  background: theme('colors.primary');
  color: theme('colors.white');

  &:before {
    color: theme('colors.primary');
    border-color: theme('colors.primary');
  }
}

.infobox--success {
  color: theme('colors.success');
  background: white;
  border: 2px solid theme('colors.success');

  &:before {
    content: '✓';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    background-color: theme('colors.white');
    color: theme('colors.success');
    border: 2px solid theme('colors.success');
    border-radius: 50%;
  }
}
