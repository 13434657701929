.productListSidebarWrap {
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    gap: theme('space.7');
  }
}

.sidebarNavigation {
  display: none;

  @media (min-width: 1024px) {
    display: block;
    width: 15%;
    min-width: 185px;
    padding-top: 44px;
  }
}

.productListWithSidebar {
  @media (min-width: 1024px) {
    width: 85%;
  }
}
