.sliderBtnWrap {
  display: inline-grid;
  column-gap: 25px;
  margin: theme('spacing.6');

  @media (max-width: 1023px) {
    column-gap: 40px;
  }
}

.sliderBtn {
  width: 26px;
  height: auto;
  display: flex;
  align-items: center;
}

.sliderBtnNext {
  grid-row: 1;
}

.sliderBtnPrev {
  transform: rotate(180deg);
  grid-row: 1;
}
