.location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: theme('spacing.3');
  border-bottom: 1px solid theme('colors.gray100');
  padding: theme('spacing.4') 0;
  font-size: 13px;
  line-height: 18px;

  @media (min-width: 768px) {
    gap: theme('spacing.5');
  }

  &:first-child {
    border-top: 1px solid theme('colors.gray100');
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.locationMain {
  display: flex;
  gap: theme('spacing.5');
  justify-content: space-between;
  flex-grow: 1;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
  }
}

.locationInfo {
  display: flex;
  flex-direction: column;
}

.locationName {
  font-size: 14px;
  font-weight: 700;
}
