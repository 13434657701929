.cardBalance {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: theme('spacing.4') theme('spacing.7');

    @media screen and (min-width: 1024px) {
      grid-template-columns: 3fr 2fr;
    }
  }

  .inputGroup {
    min-height: 28px;
    display: block;
    align-items: center;
    margin-bottom: theme('spacing.4');

    @media screen and (min-width: 1024px) {
      display: flex;
    }


    & > {
      label,
      span {
        &:first-child {
          flex: 0 0 200px;
        }
      }
    }

    &.required span:first-child {
      color: theme('colors.fontColorGray');

      &:after {
        content: ' *';
      }
    }

    input[type="text"] {
      padding: 0 theme('spacing.2');
      width: 100%;
      border: 1px solid theme('colors.gray400');
      min-height: 28px;
    }
  }
}
