.overviewVoucherWrap {
  border-top: 1px solid theme('colors.lightGrey');
}

.productsOverview {
  @media (max-width: 767px) {
    border-top: 1px solid theme('colors.lightGrey');
  }
}

.productOverviewHeadlines {
  margin: theme('space.3') 0 0;
  padding-bottom: theme('space.2');
  border-bottom: 1px solid theme('colors.lightGrey');
}
