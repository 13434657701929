.row {
  display: flex;
  column-gap: theme('spacing.3');
  width: 100%;
}

.radioButton {
  background: theme('colors.inputBackground');
  height: 52px;
  flex-shrink: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 theme('spacing.4');
  font-size: 15px;
  color: theme('colors.fontColor');
  cursor: pointer;
}

.size--small {
  font-size: 14px;
  padding: 0 6px;
  height: 48px;
}

.radioButton--isActive {
  background: theme('colors.primary');
  color: theme('colors.white');
}

.errorMessage {
  color: theme('colors.danger');
  font-size: 13px;
  font-weight: 400;
}
