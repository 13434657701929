.productSizes {
  padding-bottom: theme('space.3');
}

.sizesWrap {
  position: relative;
  font-size: theme('fontSize.sm');

  @media (max-width: 767px) {
    &:before, &:after {
      content: "";
      position: absolute;
      background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
      left: 0;
      bottom: 0;
      width: 30px;
      height: 40px;
      z-index: 1;
    }

    &:after {
      right: 0;
      left: auto;
      transform: rotate(180deg);
    }
  }

  .labelTooltipWrap {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 theme('space.1');
  }

  .sizeTooltipWrap {
    background-color: theme('colors.white');
    color: theme('colors.fontColor');
    box-shadow: 0 0 5px rgba(15, 32, 47, .5);
    width: 230px;
    z-index: 10;
  }
}

.sizesLabel {
  margin-bottom: theme('space.2');
}

.sizesFewArticlesLeftNote {
  color: theme('colors.discountedPrice');
  margin-left: theme('space.1');
}

.sizesContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: theme('space.2');

  .arrowIcons {
    position: absolute;
    z-index: 2;
    color: theme('colors.gray200');

    &.left {
      left: -17px;
    }

    &.right {
      right: -17px;
    }
  }

  @media (max-width: 767px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.sizeBtn {
  padding: 0 theme('space.2');
  height: 40px;
  min-width: 40px;
  position: relative;
  border: 1px solid theme('colors.gray100');

  &:first-of-type, &:last-of-type {
    z-index: 2;
  }

  @media (max-width: 767px) {
    min-width: 54px;
  }
}

.isSelected {
  border-color: theme('colors.primary');
}

.isDisabled {
  background-color: theme('colors.gray50');
  border-color: theme('colors.gray200');
  color: theme('colors.gray300');
  overflow: hidden;
  opacity: 0.7;
  cursor: not-allowed;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 150%;
    height: 1px;
    background-color: theme('colors.gray200');
    transform: translate(-50%) rotate(-45deg);
  }
}

.unavailableSizesMessage {
  color: theme('colors.discountedPrice');
}

.sizesModal {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: theme('colors.fontColor');
}

.sizesModalLabel {
  width: 15%;
  min-width: 50px;
  margin-right: theme('space.2');
  padding: theme('space.1') 0;
}

.sizesModalDropdown {
  background: theme('colors.white');
}
