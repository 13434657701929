.size {
  width: 40px;
  padding: 2px 0;
  background-color: theme('colors.gray500');
  color: #fff;
  font-size: 12px;
  text-align: center;
  cursor: default;
}

.available {
  background-color: theme('colors.storeavailability.available');
}

.onlineonly {
  background-color: theme('colors.storeavailability.onlineOnly');
  background-image: theme('colors.storeavailability.onlineOnly');
  background-size: 8px 8px;
}

.notavailable {
  background-color: theme('colors.storeavailability.notAvailable');
}
