.card {
  border: 1px solid #000;
  border-radius: 16px;
  padding: 20px 24px;
  position: relative;
  margin-bottom: 16px;
  max-width: 400px;

  &.padding {
    padding-bottom: 40px;
  }
}

.amount {
  font-weight: 600;
}

.barcodeWrap {
  margin-bottom: 12px;
}

.voucherCode {
  font-weight: 900;
  font-size: 14px;
  display: flex;
  column-gap: 32px;
}

.description {
  max-width: 75%;
  margin-top: 12px;
  font-size: 11px;
  line-height: 1.25;
}

.watermark {
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 12px;
  font-weight: 800;
}
