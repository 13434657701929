.addressOverviewWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: theme('space.5');
  padding: theme('space.5') 0;
  border-top: 1px solid theme('colors.lightGrey');
}

.addressOverviewBlock {
  width: calc(25% - 20px);
  font-size: 14px;
  line-height: theme('space.5');

  strong {
    display: block;
  }

  @media (max-width: 767px) {
    width: calc(50% - 20px);
  }
}

.scrollToButton {
  text-decoration: underline;
  text-align: left;
}
