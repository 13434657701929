.navigation {
  min-height: calc(100vh - 60px);
  background: theme('colors.white');
  padding-bottom: 60px;
}

.navigationItem {
  color: theme('colors.fontColor');
  background: theme('colors.white');
}
