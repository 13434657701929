.confirmationBoxWrapper {
  margin-bottom: theme('space.3');

  .headline {
    margin: theme('space.7') 0;
    font-size: 19px;
    font-weight: 500;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 27px;
    }
  }

  .text {
    min-height: 70px;
    font-size: 13px;
    margin: theme('space.3') 0;
  }

  .ctaButtonsWrapper {
    display: flex;
    flex-direction: column;

    .ctaButtonWrapper {
      display: flex;
      justify-content: center;
      margin-bottom: theme('space.6');
    }
  }

  @media (min-width: 768px) {
    .ctaButtonsWrapper {
      flex-direction: row;

      .ctaButtonWrapper {
        display: block;
      }
    }
  }
}
