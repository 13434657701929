.items {
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .item {
      padding: theme('space.4') 0;
      border-bottom: 1px solid theme('colors.black');

      &:nth-child(-n + 2) {
        border-top: 1px solid theme('colors.black');
      }

      &:nth-child(odd) img {
        padding-right: theme('space.4');
        border-right: 1px solid theme('colors.black');
      }

      &:nth-child(even) img {
        padding-left: theme('space.4');
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .item:not(:first-child) {
      margin-top: theme('space.4');
      padding-top: theme('space.4');
      border-top: 1px solid theme('colors.black');
    }
  }
}
