.wrapper {
  @media (max-width: 1023px) {
    display: none;
  }

  display: flex;
  justify-content: center;
  background-color: theme('colors.gray50');
  padding: theme('spacing.5');
}

.wrapperInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
}

.sectionText {
  color: theme('colors.fontColor');
  font-weight: normal;
}

.searchForm {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.inputWrapper {
  width: calc(100% - 45px);

  input {
    width: 100%;
    height: 48px;
    color: theme('colors.fontColor');
  }

  ::placeholder {
    color: theme('colors.fontColor');
    opacity: 1;
  }
}

.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 48px;
  background: theme('colors.primary');
}
