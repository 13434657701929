.HeaderMainWrap {
  display: none;
  position: relative;
  z-index: 100;
  width: 100vw;
  background-color: theme('colors.primary');
  padding: 0 theme('space.4');

  @media (min-width: 1024px) {
    display: block;
  }
}

.emilioHeaderMainWrap {
  background-color: theme('colors.white');
}

.enGermanyHeaderMainWrap {
  background-color: theme('colors.enGermanyPrimary');
}

.HeaderMain {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: theme('colors.primary');
  padding: theme('space.3') 0;
}

.enGermanyHeaderMain {
  background-color: theme('colors.enGermanyPrimary');

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: rgba(237, 233, 229, .3);
  }
}

.emilioHeaderMain {
  position: relative;
  z-index: 100;
  align-items: center;
  background-color: theme('colors.white');
  color: inherit;
  padding: theme('space.4') 0;

  .emilioHeaderServiceWrap {
    width: 100%;
    max-width: 40%;

    ul {
      justify-content: flex-start;
      padding: 0;
    }
  }

  .logosWrapper {
    max-width: 20%;
    justify-content: center;
    padding: 0;

    img {
      max-width: 100%;
      max-height: 70px;
      margin: 0;
      opacity: 1;
    }
  }

  .itemsWrapper {
    font-size: 13px;
    font-weight: 300;
    color: inherit;

    .itemLinksWrapper {
      &:hover {
        a,
        button[class="basketButton"] {
          color: theme('colors.orange');
        }
      }
    }

    img {
      height: 24px;
      object-fit: contain;
      margin: 0 auto theme('space.1');
    }

    .amount {
      top: 7px;
      right: 50%;
      transform: translateX(50%);
      font-size: 10px;
      font-weight: bold;
      color: theme('colors.white');
      background-color: transparent;

      &.wishlistAmount {
        top: 4px;
        color: inherit;
      }
    }
  }
}

.logosWrapper {
  width: 100%;
  max-width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 16px;
  z-index: 1;

  a {
    &:first-child img {
      margin-left: 0;
    }

    &:hover img {
      opacity: 1;
    }
  }

  img {
    max-width: 100px;
    height: 100px;
    margin: 0 24px;
    opacity: 0.5;
    transition: all 0.2s ease-in;

    &.isActive {
      opacity: 1;
    }

    &.emilioAdaniNotSelected {
      filter: brightness(0) invert(1);
    }
  }
}

.itemLinksWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &.enGermanyItemLinksWrapper {
    span {
      transition: all 200ms ease-in-out;
    }

    &:hover {
      span:not(.amount) {
        opacity: 0.7;
      }
    }
  }
}

.logoutButton {
  position: absolute;
  top: calc(100% - 4px);
  left: 50%;
  transform: translateX(-50%);
  text-transform: lowercase;
  font-size: 85%;
  opacity: .7;
  line-height: 17px;
}

.itemsWrapper {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: theme('colors.white');
  padding-left: 16px;
  z-index: 1;

  a,
  & button[class^="basketButton"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & > div {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        max-height: 27px;
      }
    }
  }
}

.amount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  color: theme('colors.fontColor');
  background-color: theme('colors.white');
  border-radius: 50%;
  font-size: 11px;
  line-height: 14px;

  &.enGermanyAmount {
    color: theme('colors.enGermanyPrimary');
  }
}
