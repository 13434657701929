.locations {
  margin-top: theme('space.6');
  margin-bottom: theme('space.3');
}

.showAll {
  display: block;
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  margin-top: theme('spacing.4');
  padding-top: theme('spacing.4');
  border-top: 1px solid theme('colors.gray100');
}
