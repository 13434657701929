.odpWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: theme('space.6');
  font-size: 14px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.odpImagesWrap,
.odpInfoWrap {
  flex-grow: 1;
  max-width: 50%;
}

.odpImagesWrap {
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: calc(100% / 3);
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.odpInfoWrap {
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: calc((100% / 3) * 2);
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.odpHeading {
  h1 {
    font-size: 18px;
    margin-bottom: theme('space.1');
    text-transform: uppercase;
  }
}

.odpAddToCartPrice {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 theme('space.8');
  font-size: 18px;
  background-color: theme('colors.gray100');
  min-width: 135px;

  @media (max-width: 575px) {
    max-height: 50px;
    padding: theme('space.4');
  }
}

.odpAddToCartBtn {
  width: 100%;
  max-width: 300px;
  margin-right: theme('space.1');
  text-transform: uppercase;

  @media (max-width: 575px) {
    max-width: 100%;
  }
}

.odpWishlistWrapper {
  position: static;

  button {
    height: 45px;
    padding: theme('space.3');
  }
}

.odpShippingCostsWrap {
  color: theme('colors.gray600');
  padding-top: theme('space.4');
  border-top: 1px dashed theme('colors.gray500');

  a {
    text-decoration: underline;
  }

  * {
    margin: 0;
    line-height: 20px;
  }
}

.odpShippingCosts {
  position: relative;
  width: fit-content;
}

.odpShippingCostsTooltip {
  position: absolute;
  bottom: 170%;
  right: -30px;
  background-color: theme('colors.white');

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -7px;
    width: 14px;
    height: 14px;
    background-color: theme('colors.white');
    transform: translateX(-50%) rotate(45deg);
    z-index: 11;
  }

  &:after {
    box-shadow: 0 0 5px rgba(15, 32, 47, .5);
    z-index: 9;
  }
}

.odpShippingCostsTooltipText {
  position: relative;
  padding: theme('space.3');
  background-color: theme('colors.white');
  box-shadow: 0 0 5px rgba(15, 32, 47, .5);
  font-size: 12px;
  text-align: center;
  z-index: 10;
}
