.paymentInputsWrap {
  margin-bottom: theme('space.4');

  iframe {
    width: 100%;
    border: 1px solid theme('colors.gray100');
    padding: 0 theme('space.4');
  }
}

.paymentInputs {
  position: relative;
}

.paymentLabel {
  margin: theme('space.8') 0 theme('space.4');
  text-align: center;
  font-weight: 700;
}

.paymentInfoText {
  margin-bottom: theme('space.4');
  font-size: 13px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  cursor: pointer;
  width: 24px;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 24px;
    width: 2px;
    background: #888;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
