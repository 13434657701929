#pinchContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: theme('colors.white');
}

.iconContainer {
  position: absolute;
  top: 0;
  right: 0;

  &.padded {
    padding: 5px;
  }

  & > * {
    display: inline-block;
  }
}
