.buttonWrap {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: theme('spacing.3');

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.content {
  padding-bottom: theme('space.4');

  a:link, a:visited {
    text-decoration: underline;
  }
}
