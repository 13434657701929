.imageSliderInnerWrap {
  width: 100%;
  height: auto;
  position: relative;
}

.sliderImage {
  z-index: -1;
}

.sliderImageMobile {
  z-index: -1;
  width: 100%;
  height: auto;
}

.imageSliderContentBlock {
  z-index: 3;
  background-color: theme('colors.enGermanyPrimary');
  height: 300px;
  width: 32%;
  position: absolute;
  bottom: 60px;
  left: 10%;
  padding: theme('spacing.14');

  @media (max-width: 1200px) {
    padding: theme('spacing.6') theme('spacing.5');
    height: 60%;
  }

  @media (max-width: 1023px) {
    left: 0;
    width: 89%;
    height: 170px;
    bottom: 80px;
  }
}

.imageSliderContentHeadline {
  display: inline-block;
  color: theme('colors.white');
  padding-bottom: theme('spacing.4');
  border-bottom: 3px solid #029cd8;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: .08em;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 16px;
  }
}

.imageSliderContentText {
  color: theme('colors.white');
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 30px;

  @media (max-width: 1200px) {
    font-size: 25px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
  }
}

.slideNavigationWrap, .imageSliderNavigationWrap {
  position: absolute;
  right: 50%;
  bottom: 0;
  height: 80px;
  width: 590px;

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 1023px) {
    right: 0;
    width: 160px;
  }
}

.imageSliderNavigationWrap {
  z-index: 2;
  display: inline-flex;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
}

.slideNavigationWrap {
  background-color: theme('colors.white');
  opacity: .8;
  z-index: 1;
}
