.itemWrap {
  margin: theme('space.6') 0;
}

.item + .item {
  margin-top: theme('space.8');
  padding-top: theme('space.6');
  border-top: 1px solid theme('colors.white');
}

.description {
  color: theme('colors.gray400');
  font-size: 12px;
}
