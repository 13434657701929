.productAddToCart {
  padding-bottom: theme('space.5');
  margin-bottom: theme('space.5');
}

.addToCartActions {
  display: flex;
  align-items: center;
  gap: theme('space.1');

  button {
    &:first-child {
      flex-grow: 1;
    }

    &:disabled {
      opacity: 0.4;
    }
  }
}

.addToCartButton {
  &:disabled {
    cursor: not-allowed;
  }
}

.notBuyableText, .shippingCostsNote {
  font-size: 10px;
  margin-top: theme('space.2');
}

.wishlistWrapper {
  position: static;

  button {
    padding: theme('space.3');
  }
}
