.outfitSliderWrap {
  grid-row-start: var(--row-start, 1);
  grid-row-end: var(--row-end, 1);
  grid-column: span 3 / -1;
  padding: theme('space.3') 0;

  @media (max-width: 767px) {
    grid-column: span 2 / -1;
  }

  :global .slider__container {
    .swiper {
      padding-bottom: 0 !important;

      &-button {
        &-prev, &-next {
          top: calc(50% - 50px);

          @media (min-width: 768px) {
            top: calc(50% - 60px);
          }
        }
      }
    }
  }
}
