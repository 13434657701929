.productFlag {
  color: theme('colors.white');
  padding: theme('space.1');
  max-height: 20px;

  @media (min-width: 1024px) {
    min-width: 48px;
    max-height: 25px;
  }

  &.new:not(.flagImage) {
    background-color: theme('colors.flags.new');
  }

  &.flagImage {
    padding: 0;
    text-align: left;

    img {
      aspect-ratio: initial !important;
      width: auto !important;
      display: inline-block;
    }
  }
}
