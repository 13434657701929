.productFlag {
  color: theme('colors.white');
  padding: theme('space.1');
  max-height: 20px;

  @media (min-width: 1024px) {
    min-width: 48px;
    max-height: 25px;
  }

  &.sale {
    background-color: theme('colors.flags.sale');
  }
}
