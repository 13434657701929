.sticky {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  width: 100% !important;
  left: 0;
  background-color: theme('colors.white');

  div[class^="navigation_searchFormWrapper"] form {
    margin-right: theme('space.4');
  }
}

.emilioSticky {
  border-bottom: 0 none !important;

  &:before,
  &:after {
    display: none;
  }
}

.stickyWrapper {
  position: relative;
  z-index: 1000;
  background-color: theme('colors.white');
  padding: 0 theme('space.4');
}

.enGermanyStickyWrapper {
  color: theme('colors.white');
  background-color: theme('colors.enGermanyPrimary');

  &:before,
  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    left: -50vw;
    top: 0;
    background-color: theme('colors.enGermanyPrimary');
  }

  &:after {
    left: auto;
    right: -50vw;
  }

  .wrap {
    background-color: theme('colors.enGermanyPrimary');

    .navFlyout {
      > a {
        border: 0 none;
        transition: all 200ms ease-in-out;

        &:after {
          display: none;
        }
      }

      &:hover {
        > a {
          opacity: 0.7;
        }

        .showDropdownMenu {
          border-top: 0 none;
        }
      }
    }

    .dropdownMenu {
      color: theme('colors.black');
    }

    div[class^="desktop-search_navigationSearchFlyout"] {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), theme('colors.enGermanyPrimary') 20%);
    }

    div[class^="desktop-search_searchFormWrapper"] {
      form,
      input {
        background-color: transparent;
        border: 0 none;
      }

      input {
        border-bottom: 1px solid rgba(237, 233, 229, .3);

        &::placeholder {
          color: theme('colors.white');
          opacity: 0.3;
          font-weight: 600;
        }
      }
    }

    div[class^="aa-Panel autocomplete_searchFlyoutContentWrapper"] {
      color: theme('colors.black');
    }
  }
}

.emilioStickyWrapper {
  border-bottom: 1px solid theme('colors.primaryLight');

  &:before,
  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    left: -50vw;
    top: 1px;
    border-bottom: 1px solid theme('colors.primaryLight');
  }

  &:after {
    left: auto;
    right: -50vw;
  }
}

.wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: auto;
  background-color: theme('colors.white');
  z-index: 1;
}

.navigationWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 230px 0 0;
}

.navFlyout {
  &:first-child > a {
    margin-left: 0;
  }

  &:last-child > a:after {
    content: none;
  }

  > a {
    position: relative;
    display: block;
    padding: 4px 0;
    margin: 0 16px 16px;
    border-bottom: 2px solid transparent;

    &:after {
      content: '';
      position: absolute;
      top: 7px;
      right: -16px;
      width: 1px;
      height: 16px;
      background-color: theme('colors.gray100');
    }
  }

  &:hover {
    > a {
      border-bottom-color: theme('colors.primary');
    }

    .showDropdownMenu {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 8px 24px 24px;
      z-index: 1000;
      pointer-events: auto;
      border-top: 13px solid theme('colors.white');
    }
  }
}

.emilioNavFlyout {
  > a {
    padding-bottom: theme('space.3');
    border-bottom-width: 5px;

    &:after {
      display: none;
    }
  }

  &:hover {
    > a {
      border-color: theme('colors.orange');
    }
  }

  .dropdownMenuWrapper {
    a:hover {
      color: theme('colors.orange');
    }
  }
}

.dropdownMenu {
  width: 100vw;
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  overflow: hidden;
  opacity: 0;
  height: 0;
  padding: 0 24px;
  margin: 0;
  z-index: -1;
  pointer-events: none;
  border-top: 3px solid theme('colors.white');
  background-color: rgba(240, 242, 242, 95%);
  transition: all 0.2s ease-in-out;
}

.dropdownMenuAds {
  display: flex;
  gap: theme('space.8');
  max-width: 530px;
  float: right;
  white-space: nowrap;
  padding: theme('space.4') 0 0 theme('space.4');

  .navImageWrap {
    position: relative;
    height: fit-content;

    .buttonWrap {
      display: flex;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: theme('space.4');

      .button {
        background-color: theme('colors.primary');
        color: theme('colors.white');
        font-size: theme('fontSize.xs');
        line-height: 14px;
        padding: theme('space.2');
      }
    }
  }
}

.dropdownMenuWrapper {
  max-width: 1280px;
  margin: 0 auto;
  column-count: 5;
  column-fill: balance;
  column-gap: 10px;
  font-size: 14px;

  > li {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    column-count: 1;
    padding-top: 16px;

    > a {
      font-size: 16px;
      font-weight: 700;
      border-bottom: 2px solid transparent;
    }

    a:not(.btn) {
      display: block;
      word-break: break-word;
      hyphens: auto;
      padding: 4px 0;
    }
  }
}

.dropdownMenuSubNav {
  a {
    font-weight: 300;
    line-height: 1.1;
  }
}

.loadingOuterWrap {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  height: 72px;
}

.loadingInnerWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

.loadingLabel {
  font-weight: 500;
  font-size: 13px;
}
