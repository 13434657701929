.sidebarCategory {
  font-size: theme('fontSize.sm');
  margin-bottom: theme('space.2');

  & > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: theme('space.2');

    &:hover {
      font-weight: bold;

      @media (hover: none) {
        font-weight: normal;
      }
    }

    & > div {
      min-width: 12px;
    }
  }
}

.sidebarCategory:last-child {
  margin-bottom: 0;
}

.emilioSidebarCategory {
  & > a:hover {
    font-weight: inherit;
    color: theme('colors.orange');
  }

  &.sidebarCategoryActive {
    & > a {
      font-weight: bold;
    }
  }

  .sidebarSubcategory {
    a {
      font-weight: 400;

      &:hover {
        font-weight: 400;
        color: theme('colors.orange');
      }
    }

    &.sidebarSubcategoryActive {
      & > a {
        font-weight: bold;
      }
    }
  }
}

.sidebarCategoryActive,
.sidebarSubcategoryActive {
  & > a {
    font-weight: bold;
  }
}

.sidebarSubcategoryActive {
  div[class^="icon-custom_iconWrap"] {
    opacity: 1;
  }
}

.sidebarSubcategory {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: theme('space.2');

  &:first-child {
    margin-top: theme('space.2');
  }

  a {
    margin-left: theme('space.1');
  }

  &:hover {
    a {
      font-weight: bold;

      @media (hover: none) {
        font-weight: normal;
      }
    }

    div[class^="icon-custom_iconWrap"] {
      opacity: 1;
    }
  }
}

.sidebarCategoryIcon {
  opacity: 0;

  &.contentSidebarCategoryIcon {
    opacity: 1;
  }
}
