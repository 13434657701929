.myAccount {
    margin-bottom: theme('spacing.6');
}

.heading {
    padding: theme('spacing.7') 0 theme('spacing.10');
}

.accountNavPoints {
    width: 100%;
    padding-right:theme('spacing.4');
}

.emilioAccountNavPoints {
  .accountNavPoint {
    &:hover {
      font-weight: 300;
      color: theme('colors.orange');

      div[class^="icon-custom_iconWrap"] svg path {
        fill: theme('colors.orange');
      }
    }

    &.accountNavPointActive:hover {
      font-weight: 700;
    }
  }
}

.accountNavPointWrapper {
    border-bottom: 1px solid theme('colors.lightGrey');
}

.accountNavPoint {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    font-size: 15px;
    color: theme('colors.fontColor');

    &:hover {
        font-weight: 700;
    }
}

.accountNavPointActive {
  font-weight: 700;
}

.accountSubnavPoint {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    font-size: 12px;
    color: theme('colors.fontColor');

    &:hover {
        font-weight: 700;
    }
}

.accountFooterIconsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin: theme('spacing.15') theme('spacing.20') theme('spacing.8') 0;
}

.accountFooterIcon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: theme('spacing.4');
    max-width: 180px;

    img {
        max-width: 65px;
        max-height: 65px;
    }

    span {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        margin-left: theme('spacing.2');
    }
}
