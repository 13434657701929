.shippingWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: theme('spacing.3') 0;
  padding: theme('spacing.4');
  width: 100%;
  box-shadow: 0 0 0 1px theme('colors.lightGrey');
  cursor: pointer;

  &:before {
    content: '';
    margin: theme('spacing.3');
    width: 18px;
    height: 18px;
    background-color: theme('colors.gray50');
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 1024px) {
      position: absolute;
      right: theme('space.3');
    }
  }

  @media (hover: hover) {
    &:hover:before {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACISURBVHgBtdDRDUAwEAbgOwNghE6AVUzASEZgAjGBmEBHMIINzrVFpKK0if+p6d3X9goQGPRpTkWREtCollEAKghw+HTjFSFgty6yxhCk9iNV8EUacmFORD7bB7iQhgg0mYFpPPAbUkHTmLX8UxUvJTeWjHoXOqGF98IzuiUWWcPzEj+1hb+yAXODSJq4FOkKAAAAAElFTkSuQmCC');
      opacity: 0.5;
    }
  }

  &.isChecked {
    box-shadow: 0 0 0 2px theme('colors.primary');

    &:before {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACISURBVHgBtdDRDUAwEAbgOwNghE6AVUzASEZgAjGBmEBHMIINzrVFpKK0if+p6d3X9goQGPRpTkWREtCollEAKghw+HTjFSFgty6yxhCk9iNV8EUacmFORD7bB7iQhgg0mYFpPPAbUkHTmLX8UxUvJTeWjHoXOqGF98IzuiUWWcPzEj+1hb+yAXODSJq4FOkKAAAAAElFTkSuQmCC');
    }

    &:hover:before {
      opacity: 1;
    }
  }

  .shippingProvider {
    display: flex;
    width: 100%;
    align-items: center;

    img {
      max-width: 80px;
      margin-right: theme('spacing.2');
    }
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.2');
    margin-right: theme('spacing.10');
    margin-left: theme('spacing.3');

    .title {
      font-size: theme('fontSize.lg');
      font-weight: 600;

      @media (max-width: 1024px) {
        font-size: theme('fontSize.xs');
      }
    }

    .subtext {
      display: flex;
      align-items: center;
      gap: theme('space.3');
      font-size: theme('fontSize.sm');

      @media (max-width: 1024px) {
        font-size: theme('fontSize.xs');
      }
    }
  }
}
