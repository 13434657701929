.productInfo {
  padding-bottom: theme('space.5');
  margin-bottom: theme('space.5');

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
    margin-bottom: unset;
  }
}

.infoProductHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoProductName {
  font-size: 17px;
  font-weight: 200;
  line-height: 22px;
  text-transform: capitalize;

  @media (max-width: 767px) {
    font-size: 19px;
  }
}

.productName {
  font-size: theme('fontSize.xl');
  color: theme('colors.fontColor');
}

.infoManufacturer {
  display: block;
  font-weight: 500;
  font-size: theme('fontSize.sm');
  text-transform: none;
  color: theme('colors.gray500');
  margin-bottom: theme('space.1');

  @media (max-width: 767px) {
    width: fit-content;
  }
}

.infoFlags {
  margin-bottom: theme('space.3');

  @media (max-width: 1023px) {
    text-align: unset;
  }
}

.availabilityModalTitle {
  color: theme('colors.fontColor');
}

.pushModalTitle {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: theme('space.3');
  color: theme('colors.fontColor');
  border-bottom: 1px dotted theme('colors.gray400');
}

.pushModalMarkdown {
  color: theme('colors.fontColor');
}

.sizesWrapper {
  padding-bottom: theme('space.5');
  margin-bottom: theme('space.5');
}

.sizesInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: theme('space.3');
}

.storeAvailabilityBtn {
  display: flex;

  img {
    margin-right: theme('spacing.2');
  }
}

.storeAvailabilityBtn, .sizeAdvisorBtn {
  font-size: theme('fontSize.sm');
  text-decoration: underline;
}
