.summaryRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 14px;

  @media (min-width: 1024px) {
    text-align: right;
  }
}

.summaryLabel {
  @media (min-width: 1024px) {
    flex: 0 0 60%;
  }
}

.summaryPrice {
  margin: 0;
  font-size: inherit;
}

.totalSum {
  margin: theme('spacing.4') 0;
  font-weight: 700;
  font-size: 18px;
}

.removeDiscountButton {
  margin-right: theme('spacing.2');
}

.shippingCostsNote {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-align: right;

  @media (max-width: 1023px) {
    text-align: left;
  }
}
