.productSlider {
  h2,
  h3 {
    font-weight: 700;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 3px;
  }

  :global .slider__container {
    .swiper {
      &-button {
        &-prev, &-next {
          opacity: 1;
          width: 30px;
          top: calc(50% - 90px);
          transform: translateY(50%);
          background: hsla(0, 0%, 100%, 0.85);

          &::after {
            font-size: 15px;
            font-weight: 700;
            color: #000000;
          }

          @media (max-width: 767px) {
            --swiper-navigation-size: 40px;
            background-color: theme('colors.white');
          }
        }

        &-prev {
          left: 0;
        }

        &-next {
          right: 0;
        }

        &-disabled {
          display: none;
        }
      }

      &-wrapper {
        .swiper-slide {
          user-select: none;

          img {
            aspect-ratio: 3 / 4;
            object-fit: cover;
            width: 100%;
          }
        }

      }
    }
  }

  &.isStartPage {
    :global .slider__container {
      .swiper {
        &-button {
          &-prev, &-next {
            --swiper-navigation-size: 40px;

            &::after {
              font-size: 25px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .swiperWrapperMobile {
    left: 7%;
    width: fit-content;
  }
}
