.HeaderMinimizedWrap {
  display: block;
  position: relative;
  z-index: 100;
  width: 100vw;
  padding: 0 theme('space.4');
  background-color: theme('colors.primary');

  @media (min-width: 1024px) {
    &:not(.isCheckout) {
      display: none;
    }
  }
}

.emilioHeaderMinimizedWrap {
  background-color: theme('colors.white');
  border-bottom: 1px solid theme('colors.gray300');
  margin-bottom: theme('space.2') !important;

  @media (max-width: 1023px) {
    border-bottom-width: 2px;
  }

  .emilioHeaderMinimized {
    border-bottom: 0 none;
  }
}

.HeaderMinimized {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  background-color: theme('colors.primary');
  padding: theme('space.2') 0;
}

.emilioHeaderMinimized {
  background-color: theme('colors.white');
  border-bottom: 2px solid theme('colors.gray300');

  .logoWrapper {
    img {
      width: 100%;
      max-width: 150px;
    }
  }

  .tsWrapper {
    .tsText {
      color: inherit;

      * {
        color: inherit;
      }
    }
  }
}

.emilioCheckoutHeaderMinimized {
  padding: theme('space.4') 0;

  .logoWrapper {
    @media (min-width: 500px) {
      justify-content: flex-start;
    }

    img {
      @media (min-width: 768px) {
        max-width: 190px;
      }
    }
  }

  .tsWrapper {
    display: flex;
    max-width: 100%;

    @media (max-width: 500px) {
      display: none;
    }

    @media (max-width: 600px) {
      img {
        display: none;
      }
    }
  }
}

.logoWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100px;
    width: 100px;
  }

  @media (min-width: 768px) {
    max-width: 55%;
  }

  @media (min-width: 1024px) {
    justify-content: flex-end;

    img {
      max-width: 150px;
      margin-right: 53px;
    }
  }
}

.tsWrapper {
  width: 100%;
  max-width: 45%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
  }

  .tsText {
    font-size: 11px;
    line-height: 13px;
    margin: 0 20px;
    color: theme('colors.white');

    * {
      color: theme('colors.white');
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
}
