.headline {
  margin: theme('space.7') 0;
  font-size: 19px;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 27px;
  }
}

.forgotPasswordFormWrapper {
  padding: theme('space.3') theme('space.6') theme('space.6');
  font-size: 13px;

  p {
    line-height: 17px;
    margin: 0 0 theme('space.3');
  }

  .subHeading {
    margin-bottom: theme('space.3');

    > p {
      margin: 0;
    }
  }

  .description {
    margin-top: theme('space.3');
  }

  .forgotPasswordForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .inputLabel {
      align-self: flex-start;
    }

    .inputInvalid {
      color: theme('colors.danger');
    }

    .inputField {
      font-size: 13px;
      padding: 0 theme('space.2');
      min-height: 28px;
    }

    .inputInvalidField {
      border-color: theme('colors.danger');
      border-width: 2px;
      background-color: theme('colors.lightRed');
    }

    .inputInfoText {
      font-size: 12px;

      > span {
        margin-right: theme('space.1');
      }
    }

    .ctaButton {
      text-transform: uppercase;
      font-size: 125%;
      margin: theme('space.3') 0;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    padding: theme('space.6');
    width: 60%;

    .forgotPasswordForm {
      display: inline-grid;
      grid-gap: theme('space.3') theme('space.19');
      grid-auto-rows: max-content;
      grid-template-columns: max-content 1fr;

      .inputLabel {
        align-self: center;
        margin-top: 0;
      }

      .inputField {
        width: 100%;
      }

      .inputInfoText {
        grid-column-start: 2;
        margin-top: -12px;
      }

      .ctaButton {
        grid-column-start: 2;
        margin-top: theme('space.2');
      }
    };
  }
}
