.sortingWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sortingLabel {
  font-size: 14px;
  font-weight: 500;
  color: theme('colors.gray400');
  padding-right: theme('space.5');
}

.sortingDropdown {
  width: 150px;
  padding: 0 theme('space.1');
  font-size: 14px;
  border: 0 none;
  border-bottom: 2px solid theme('colors.primary');

  button[type="button"] {
    font-size: 13px;
  }
}
