.enGermanySliderWrap {
  position: relative;

  :global .slider__container {
    .swiper {
      &-button {
        &-prev, &-next {
          opacity: 1;
          width: 30px;
          top: calc(50% - 100px);
          transform: translateY(50%);
          --swiper-navigation-size: 40px;
          background-color: theme('colors.white');

          &::after {
            font-size: 15px;
            font-weight: 700;
            color: theme('colors.black');
          }

          @media screen and (min-width: 768px) {
            --swiper-navigation-size: 100px;
            background: hsla(0, 0, 100%, 0.85);
          }
        }

        &-prev {
          left: 0;
        }

        &-next {
          right: 0;
        }

        &-disabled {
          display: none;
        }
      }

      &-pagination {
        display: none;
      }

      @media screen and (min-width: 768px) {
        .swiper-slide-active {
          .productBoxInfo {
            div:not(div[class^="price_newPrice"]) {
              color: theme('colors.white');
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      overflow: visible;

      .swiper {
        overflow: visible;

        div[class^="product-box_productBox"] {
          border: 0 none;
        }

        &-pagination {
          display: block;
          bottom: auto;
          top: -55px;

          &-bullet {
            opacity: 1;
            background-color: theme('colors.gray200');

            &.slider__bullet--active {
              transform: scale(1.3);
              background-color: theme('colors.enGermanyBlue');
            }
          }
        }
      }
    }
  }
}

.caption {
  padding: theme('space.8') 0 theme('space.20');
  position: relative;
  color: theme('colors.white');
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  z-index: 1;

  @media screen and (min-width: 768px) {
    padding: theme('space.12') calc((100% / 3) * 2) theme('space.12') 0;
    text-align: left;
  }

  .subheadline {
    display: inline-block;
    padding-bottom: theme('space.2');
    margin-bottom: theme('space.2');
    letter-spacing: 3px;
    border-bottom: 3px solid theme('colors.enGermanyBlue');
  }

  .headline {
    font-size: 40px;
    line-height: 50px;
    color: inherit;
    padding-right: theme('space.2');

    @media screen and (min-width: 1024px) {
      font-size: 50px;
      line-height: 60px;
    }
  }

  .captionLink {
    display: block;
    margin-top: theme('space.4');
    color: theme('colors.enGermanyBlue');
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    @media screen and (min-width: 768px) {
      position: absolute;
      right: 0;
      bottom: theme('space.13');
      margin-top: 0;
    }
  }
}

.sliderBackground {
  width: 120%;
  height: 50%;
  position: absolute;
  top: 0;
  left: -10%;
  right: auto;
  background-color: theme('colors.enGermanyPrimary');

  &:after {
    content: "";
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -6px;
    background-color: theme('colors.enGermanyPrimary');
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    height: 100%;
    left: auto;
    right: calc((100% / 3) * 2);
  }
}
