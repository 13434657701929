.pdpDescription {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: theme('space.16') 0;

  @media (max-width: 767px) {
    margin: 0 0 theme('space.10') 0;
  }
}

.pdpDescSection {
  flex-basis: 50%;
  justify-content: flex-end;
  width: 50%;
  font-size: theme('fontSize.base');

  @media (max-width: 1023px) {
    flex-basis: 100%;
    width: 100%;
  }
}

.pdpDescInfo {
  margin-bottom: theme('space.6');

  ul {
    list-style-type: disc;
    padding-inline-start: theme('space.10');
    margin-block-end: theme('space.3');
    margin-block-start: theme('space.4');
    font-size: theme('fontSize.base');

    &:last-child {
      margin-block-end: 0;
      margin-block-start: 0;
    }

    li span:first-child {
      padding-right: theme('space.1');
    }
  }
}

.pdpDescTitle {
  font-size: theme('fontSize.lg');
  line-height: 20px;
  text-transform: uppercase;
}

.pdpDescMaterial {
  margin-top: theme('space.10');
}

.pdpDescMaterialName {
  margin: theme('space.5') 0;
}

.pdpDescLabelCareWrap {
  cursor: pointer;
}

.pdpDescTooltipWrap {
  background-color: theme('colors.white') !important;
  color: theme('colors.fontColor') !important;
  box-shadow: 0 0 5px rgba(15, 32, 47, .5) !important;
}
