.actionBannerWrap {
  position: relative;
  z-index: 100;
  width: 100vw;
  padding: 0 theme('space.4');
  background-color: var(--backgroundColor);
  color: var(--textColor);

  .actionBannerSlider {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.actionBannerSlider {
  position: relative;
  background-color: var(--backgroundColor);
  color: var(--textColor);

  & > div[class="slider__container"] {
    padding: 0 !important;
  }
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: theme('spacing.2');
  padding: theme('spacing.2') 0;
}

.actionBannerMarkdown > * {
  padding: 0 !important;
  margin: 0 !important;
  color: inherit !important;
  font-size: 12px !important;
  text-align: center !important;
}
