.textareaWrap {
  background: theme('colors.lightGrey');
  display: inline-flex;
  width: 100%;
  position: relative;
}

.textarea {
  border: none;
  background: none;
  font-size: 15px;
  color: theme('colors.fontColor');
  appearance: none;
  width: 100%;
  -webkit-appearance: none;
}

.textarea::placeholder {
  font-size: 15px;
  color: theme('colors.fontColor');
  opacity: 0;
}

.textarea:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.placeholder {
  color: theme('colors.fontColor');
  font-size: 15px;
  position: absolute;
  top: 12px;
  left: 12px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.textarea:focus + .placeholder,
.textarea-has-content .placeholder {
  font-size: 12px;
  font-weight: 500;
  transform: translateY(-8px);
}

.style--grey {
  background: #f2f2f2;
}

.style--white {
  background: theme('colors.white');
  border: 1px solid theme('colors.gray400');
}

.errorMessage {
  color: theme('colors.danger');
  font-size: 13px;
  font-weight: 400;
}
