.infoModalContent p + p {
  margin-top: 16px;
}

.infoModalContent a:link,
.infoModalContent a:visited {
  text-decoration: underline;
}

.infoLinkWrap {
  display: flex;
  column-gap: 8px;
  align-items: center;

  @media (max-width: 1023px) {
    justify-content: center;
  }
}

.passwordBlock {
  position: absolute !important;
  pointer-events: none;
  opacity: 0;
}

.passwordBlockIsVisible {
  position: relative !important;
  pointer-events: all;
  opacity: 1;
}

.inputWrap {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.errorCross {
  color: theme('colors.danger');
  width: 28px;
  position: absolute;
  right: 44px;
  top: 50%;
  transform: translateY(-50%);
}

.btnWrap {
  @media (max-width: 1023px) {
    margin-right: unset;
    width: 100%;
  }
}

.loginError {
  padding-top: 0.5rem;
  color: theme('colors.danger');
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
}
