.container {
  .markdown {
    display: inline-block;
    margin-left: theme('spacing.1');

    * {
      margin: 0;
      line-height: 20px;
    }
  }

  .tooltip {
    --rt-opacity: 1;
    background: theme('colors.white');
    color: theme('colors.fontColor');
    box-shadow: 0 0 5px rgba(15, 32, 47, .5);
    font-size: 12px;
    text-align: center;
    border-radius: unset;
  }
}
