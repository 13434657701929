.itemWrap {
  @media (max-width: 767px) {
    display: flex !important;
    gap: theme('space.4');
  }
}

.checkoutItemWrap {
  padding: theme('space.5') 0;
  margin: 0;
  border-bottom: 0 none;
}

.refusedItemWrap {
  opacity: 0.3;

  .itemDiscountDescriptionWrap {
    margin: 0 0 theme('space.1');
  }
}

.itemImage {
  width: 20%;

  img {
    margin: auto;
  }

  @media (max-width: 767px) {
    width: 50%;
  }
}

.checkoutItemImage {
  @media (max-width: 767px) {
    width: 40%;
  }
}

.itemInfo {
  width: 60%;

  a {
    display: inline-block;
    margin-bottom: theme('space.1');
    font-size: 18px;
    font-weight: 300;
    text-decoration: none !important;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  .itemInfoSize {
    display: flex;
    gap: theme('space.1');
    margin-top: theme('space.1');

    span {
      margin-right: theme('space.1');
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .sizeSuggestion {
    margin-top: theme('space.1');

    .sizeSuggestionIcon {
      display: inline-block;
      margin-right: theme('space.1');
      vertical-align: text-bottom;
    }
  }

  .itemInfoDeliveryTime {
    margin-top: theme('space.3');

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.itemDiscountDescriptionWrap {
  margin-top: theme('space.1');
}

.itemDiscountDescription {
  display: block;
  color: theme('colors.discountedPrice');
  font-weight: bold;
}

.checkoutItemInfo {
  a {
    font-size: 16px;
    font-weight: bold;
  }
}

.itemQuantity {
  width: 20%;
  flex-direction: row !important;
  align-items: center !important;
  gap: theme('space.1');

  @media (min-width: 768px) {
    & > span {
      display: none;
    }
  }
}

.itemPrice {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  .itemPriceInfo {
    justify-content: flex-end;
    gap: theme('space.1');
    margin: 0;
    font-size: 14px;
    line-height: 18px;

    div {
      font-size: inherit;
      font-weight: bold;
    }

    @media (max-width: 767px) {
      justify-content: flex-start;
    }
  }

  .checkoutPriceInfo {
    display: block;
    font-size: 16px;

    @media (max-width: 767px) {
      display: flex;
    }
  }

  .itemPriceSaved {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: theme('space.1');
    white-space: nowrap;
  }
}

.itemDelete {
  margin: theme('space.3') 0 theme('space.5');
  text-decoration: underline;
  white-space: nowrap;


  @media (max-width: 767px) {
    margin: theme('space.1') 0;
  }
}

.itemDeleteIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  margin-right: theme('space.2');
  color: theme('colors.white');
  background-color: theme('colors.gray400');
  border-radius: 50%;
}

.itemDropdown {
  &.wide select {
    width: 75px;
  }

  select {
    width: 65px;
    font-size: 14px;
    padding: 0 theme('space.2');
    cursor: pointer;

    &:focus {
      outline: 0 none;
      box-shadow: none;
      border-color: inherit;
    }
  }
}

.itemSize {
  max-width: 75px;
  border: 1px solid theme('colors.gray100');
  padding: 0 theme('space.2');
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 24px;
}
