.thankYou {
  margin: theme('space.6') 0 theme('space.10');
}

.thankYouContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 13px;

  @media (max-width: 1023px) {
    gap: theme('space.4');
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.orderInfo {
  max-width: 280px;

  strong {
    padding-right: theme('space.1');
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.orderConfirmationBtn {
  min-width: 380px;

  img {
    margin-right: theme('space.1');
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.trustedShopIcons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: theme('space.2');

  span {
    max-width: 120px;
  }

  @media (max-width: 1023px) {
    margin: auto;
  }
}
