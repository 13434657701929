.paymentMethodBoxWrapper {
  flex-grow: 0;
  flex-basis: 25%;
  min-height: 135px;
  margin-right: theme('space.5');
  border: 1px solid transparent;
  transition: all 250ms ease-in-out;

  @media (max-width: 1023px) {
    flex-basis: calc(50% - 6px);
    min-height: auto;
    margin: 0 theme('space.3') theme('space.3') 0;

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media (hover: hover) {
    &:hover:not(&.isDisabled) {
      border-color: theme('colors.primary');

      .paymentMethodBox {
        border: 1px solid theme('colors.primary');
      }
    }
  }

  &.isChecked:not(&.isDisabled) {
    border-color: theme('colors.primary');

    .paymentMethodBox {
      border: 1px solid theme('colors.primary');
    }
  }

  &.isDisabled {
    background-color: theme('colors.gray50');
    color: theme('colors.gray400');
    filter: grayscale(100%);

    .paymentMethodBox {
      cursor: not-allowed;
    }

    &:hover {
      border-color: transparent;

      .paymentMethodBox {
        border-color: theme('colors.lightGrey');
      }
    }
  }
}

.paymentMethodBox {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: theme('space.9') theme('space.3');
  border: 1px solid theme('colors.lightGrey');
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

.paymentMethodBoxInput {
  display: none;
}
