.base {
  color: theme('colors.primary');
  margin: 0;
  padding: 0;
}

/** Font sizes **/
.size--xs {
  font-size: 10px;
  line-height: 14px;
}

.size--base {
  font-size: 12px;
}

.size--l {
  font-size: 14px;
}

.size--xl {
  font-size: 16px;
}

.size--xxl {
  font-size: 18px;
}

/** Weights **/
.weight--semi-bold {
  font-weight: 500;
}

.weight--bold {
  font-weight: 600;
}

.transform--uppercase {
  text-transform: uppercase;
}

.color--danger {
  color: theme('colors.danger');
}

.color--success {
  color: theme('colors.success');
}

.color--white {
  color: theme('colors.white');
}

.color--black {
  color: theme('colors.black');
}

.color--info {
  color: theme('colors.info');
}

.color--warning {
  color: theme('colors.warning');
}

.color--btnIconHover {
  color: theme('colors.btnIconHover');
}

.color--linkHover {
  color: theme('colors.linkHover');
}

.color--gray50 {
  color: theme('colors.gray50');
}

.color--gray100 {
  color: theme('colors.gray100');
}

.color--gray200 {
  color: theme('colors.gray200');
}

.color--gray300 {
  color: theme('colors.gray300');
}

.color--gray400 {
  color: theme('colors.gray400');
}

.color--gray500 {
  color: theme('colors.gray500');
}

.color--gray600 {
  color: theme('colors.gray600');
}

.color--primary {
  color: theme('colors.primary');
}

.color--primaryLight {
  color: theme('colors.primaryLight');
}

.color--orange {
  color: theme('colors.orange');
}

.color--lightGrey {
  color: theme('colors.lightGrey');
}

.color--darkGrey {
  color: theme('colors.darkGrey');
}

.color--lightBlue {
  color: theme('colors.lightBlue');
}

.color--greenNeon {
  color: theme('colors.greenNeon');
}

.color--flags-new {
  color: theme('colors.flags.new');
}

.color--flags-sale {
  color: theme('colors.flags.sale');
}

.variant--text-xs {
  font-size: 12px;
}

.variant--text-sm {
  font-size: 14px;
}

.variant--input {
  font-size: 16px;
}

.variant--text-base {
  font-size: 16px;
}

.variant--link {
  font-size: 16px;
}

.variant--heading-5 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.variant--heading-4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.variant--heading-3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.variant--heading-2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.variant--heading-1 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}
