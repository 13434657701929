.notificationsContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2000;

  top: -4px;
  left: 0;
  right: 0;
}

.toastNotification {
  padding: theme('spacing.3') theme('spacing.4');
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin: theme('spacing.1') 0;
  position: relative;
  display: flex;
  column-gap: 16px;
}

.message {
  margin-top: theme('spacing.1');
}

.icon {
  flex-shrink: 0;
}

.toastNotification--type-error {
  background: theme('colors.white');
  color: theme('colors.warning');
}

.toastNotification--type-success {
  background: theme('colors.white');
  color: theme('colors.success');
}
