.form {
    @media (min-width: 1024px) {
        padding-right: 25%;
    }
}

.emailText {
    font-weight: bold;
    font-size: 12px;
}

.rowSplit {
    display: flex;
    column-gap: theme('spacing.4');
}
