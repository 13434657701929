.lineItemWrap {
  display: flex;
  gap: theme('space.4');
  padding: theme('space.4') 0;
  margin-right: theme('space.1');

  &:not(:first-child) {
    border-top: 1px solid theme('colors.lightGrey');
  }
}

.lineItemInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 16px;

  a {
    text-decoration: none;
    flex-grow: 1;

    & > div {
      color: theme('colors.gray500');
    }
  }
}

.lineItemInfoManufacturer {
  font-weight: 500;
  text-transform: none;
  color: theme('colors.gray500');
  margin-bottom: theme('space.1');
}

.lineItemInfoPrice {
  font-size: 14px;

  div {
    font-size: inherit;
  }
}

.lineItemDiscountDescription {
  color: theme('colors.discountedPrice');
  font-weight: bold;
  display: block;
}

.lineItemInfoActions {
  display: flex;
  gap: theme('space.2');
}

.lineItemSelectWrap {
  position: relative;
  min-width: 65px;
}

.lineItemSelectLabel {
  position: absolute;
  top: 0;
  font-size: 10px;
  line-height: 14px;
  color: theme('colors.gray400');
  padding: 0 theme('space.2');
  text-shadow: 0.25px 0.25px;
}

.lineItemSelect {
  width: 100%;
  font-size: 14px;
  padding: theme('space.3') theme('space.2') 0;
  border-color: theme('colors.gray100');
  background-color: theme('colors.gray50');
  background-position: 100% 80%;

  &:focus {
    outline: 0 none;
    box-shadow: none;
    border-color: inherit;
  }
}

.lineItemSize {
  height: 38px;
  max-width: 70px;
  border: 1px solid theme('colors.gray100');
  padding-top: theme('space.4');
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.lineItemInfoActionsDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: theme('space.1');
  transition: opacity 300ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}
