.base {
  max-width: 480px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1024px) {
    margin-left: 0;
  }
}

.isCentered {
  margin: 0 auto !important;
}
