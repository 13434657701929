.storeImage {
  width: 100%;
}

.assortments {
  margin-top: theme('space.3');
}

.assortment {
  display: inline-block;
  margin-right: theme('space.3');
  margin-bottom: theme('space.3');

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
    margin-right: theme('space.4');
  }
}

.storeInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mainStoreInfo {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: theme('space.4');

  @media (min-width: 768px) {
    margin-bottom: 0;
    flex-basis: 60%;
  }
}

.phone {
  margin-top: theme('spacing.4');
}

.isClosed {
  color: theme('colors.danger');
}

.openingHours {
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: 40%;
  }
}

.storeBookingLink {
  min-height: 52px;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;
  letter-spacing: normal;
  text-transform: none;
  text-align: center;
  background-color: theme('colors.storeavailability.appointment'); //Fallback
  background-color: var(--storeBookingLinkBgColo);
  color: theme('colors.white') !important;
  margin-bottom: theme('space.4');

  &:hover {
    opacity: 0.8;
  }
}

.showLocation {
  margin-top: theme('spacing.4');
  padding-top: theme('spacing.2');
  text-decoration: underline;
  text-transform: uppercase;
  border-top: 1px solid theme('colors.darkBlue');
}
