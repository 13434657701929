.button {
  background: theme('colors.primary');
  color: theme('colors.white');
  font-weight: 500;
  padding: theme('space.3') theme('space.5');
  text-align: center;
  display: flex;
  line-height: 15px;
  cursor: pointer;
  align-items: center;
  column-gap: theme('space.2');
  justify-content: center;
  border: 1px solid theme('colors.primary');
  transition: all 200ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button--size-small {
  padding: theme('space.3');
  font-size: 12px;
  font-weight: 400;
}

.button--size-large {
  width: 100%;
  padding: theme('space.4') theme('space.5');
  font-size: 14px;
}

.button--type-link {
  padding: 0;
  background: none !important;
  border: none;
  display: inline-flex;
  font-weight: 800;
}

.button--is-loading {
  opacity: 0.5;
  pointer-events: none;
}

.button--type-primary {
  background: theme('colors.white') !important;
  color: theme('colors.primary');
}

.button--type-secondary {
  background: none !important;
  border: none !important;
  text-decoration: underline;
}
