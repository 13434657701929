.wrap {
  margin-bottom: theme('space.7');

  &.isEN {
    margin-bottom: unset;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px 10px;
  font-size: 18px;
  font-weight: 500;
  color: theme('colors.fontColor');
  cursor: pointer;

  &.isEN {
    padding: theme('space.4') theme('space.2');
  }
}


.headerIconWrap {
  display: flex;
  align-items: center;

  img {
    margin-right: theme('space.3');
  }
}

.chevronIconWrap {
  transform-origin: center center;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.chevronIconWrap--is-active {
  transform: rotate(270deg);
}

.wrap + .wrap {
  border-top: 1px solid theme('colors.primary');
}

.content {
  overflow: hidden;
  height: 0 !important;

  &.isActive {
    height: auto !important;
    opacity: 1 !important;
  }

  &.isEN {
    div:first-child {
      margin: theme('spacing.2') 0 theme('spacing.4');
    }
  }
}
