.wrapper {
  margin: theme('spacing.12') auto;
  max-width: 980px;

  @media (max-width: 1023px) {
    margin: theme('spacing.6') 0;
  }
}

.title {
  display: block;
  margin-bottom: theme('spacing.6');
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.spinnerWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(165, 172, 177, 0.5);
  z-index: 1003;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelHasNoMargin {
  margin: 0;

  & > * {
    margin: 0;
  }
}

.infoModalContent {
  & > * {
    color: theme('colors.fontColor');
    font-size: 14px;
    margin: inherit !important;
  }
}

.infoWrap {
  position: absolute;
  top: 0;
  right: 0;
  padding: theme('space.1');
  background-color: theme('colors.primary');
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: theme('colors.gray500');
  }
}

.infoText {
  display: none;
  min-width: 150px;
  position: absolute;
  bottom: 100%;
  right: 50%;
  transform: translateX(50%);
  padding-bottom: theme('space.5');
  cursor: auto;

  @media (max-width: 1023px) {
    width: calc(50vw - 34px);
    right: -2px;
    transform: translateX(0);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 13px;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    background-color: theme('colors.white');
    transform: rotate(45deg);
    z-index: 11;
  }

  &:after {
    box-shadow: 0 0 5px rgba(15, 32, 47, .5);
    z-index: 9;
  }

  &.showInfo {
    display: block;
  }
}

.infoTextInner {
  position: relative;
  padding: theme('space.2');
  background-color: theme('colors.white');
  box-shadow: 0 0 5px rgba(15, 32, 47, .5);
  font-size: 12px;
  color: theme('colors.fontColor');
  z-index: 10;
}

