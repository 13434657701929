.wrap {
  background: theme('colors.primary');
}

.headline {
  color: #fff;
  font-size: 22px;
  margin: 0;
  line-height: 21px;
}

.headlineWrap {
  display: flex;
  align-items: center;
  padding: 24px 20px;
  column-gap: 12px;
  position: relative;
}

