.accountHeadline {
  h3 {
    font-size: 24px;

    & > * {
      vertical-align: middle;
    }

    &.uppercase {
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
    }

    .icon {
      display: inline-block;
      margin-right: theme('space.3');
      width: 22px;
    }

    .amountBubble {
      background-color: theme('colors.gray100');
      border-radius: 50%;
      height: 30px;
      width: 30px;
      line-height: 29px;
      font-size: 13px;
      vertical-align: middle;
      text-align: center;
      margin: 0 8px;
    }
  }

  .subHeadline {
    margin-top: theme('spacing.4');
  }

  hr {
    border-color: theme('colors.gray200');
  }
}
