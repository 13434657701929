.productListProductsWrap {
  display: grid;
  grid-template-columns: repeat(2, minmax(theme('space.31'), 1fr));
  gap: 0;
  margin-left: calc(0px - theme('space.4'));
  margin-right: calc(0px - theme('space.4'));

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: theme('space.13') theme('space.5');
    margin: 0;
  }
}

.enGermanyProductListProductsWrap {
  max-width: 850px;
  grid-template-columns: repeat(1, 1fr);
  gap: theme('space.8');
  margin: theme('space.10') auto;

  .enGermanyProductListTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;

    & > div {
      padding-bottom: theme('space.2');
      margin-bottom: theme('space.2');
      border-bottom: 3px solid theme('colors.enGermanyBlue');
    }
  }

  .progressBarWrap {
    color: theme('colors.enGermanyPrimary');
  }

  .progressBar::-webkit-progress-value {
    background-color: theme('colors.enGermanyPrimary');
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: theme('space.15');
  }
}

.viewedProductsNote {
  display: block;
  width: 100%;
  padding-top: theme('space.7');
  text-align: center;
  border-top: 1px solid theme('colors.gray100');
  grid-column: 1 / -1;
}

.progressBarWrap {
  width: fit-content;
  display: block;
  margin: auto;
  padding: 0 theme('spacing.8');
}

.progressBar {
  appearance: none;
  height: 8px;
  border: none;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.progressBar::-webkit-progress-bar {
  background-color: theme('colors.gray100');
}

.progressBar::-webkit-progress-value {
  background-color: theme('colors.primary');
}

.loadingIndicatorWrap {
  margin: 5px auto;
}
