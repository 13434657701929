.suggestion {
  transition: all 5s ease;
  height: auto;
  overflow: auto;
}

.hidden {
  height: 0;
  overflow: hidden;
  transition: all 5s ease;
}

.link {
  text-decoration: none;
}

.productBox {
  display: flex;
  align-items: center;
  gap: theme('space.4');
  padding-top: theme('space.2');
  padding-bottom: theme('space.2');
  transition: all .2s ease;
  text-decoration: none;

  &:hover {
    background-color: theme('colors.white');
  }
}

.image {
  flex: 0 0 54px;
}

.details {
  flex: 1 1 100%;
  font-size: theme('fontSize.xs');

  > span {
    display: block;
  }
}

.price {
  flex: 0 1 auto;
  flex-direction: column;
  gap: theme('space.1');
  text-align: right;

  > div {
    font-size: theme('fontSize.xs');
    text-wrap: nowrap;
  }
}

.chevron {
  color: theme('colors.gray400');
}
