.ctaSectionWrap {
  display: flex;
  justify-content: flex-end;
  padding: theme('space.5') 0;
  border-top: 1px solid theme('colors.lightGrey');
}

.ctaSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  padding-left: theme('space.4');

  @media (max-width: 767px) {
    width: 100%;
    padding-left: 0;
  }
}

.labelHasNoMargin {
  margin: 0;

  & > * {
    margin: 0;
  }
}

.submitBtn {
  margin-top: theme('space.4');
  height: auto;
}

.isDisabled {
  opacity: 0.4;
  cursor: not-allowed;

  &:hover {
    background-color: theme('colors.primary');
  }
}

.ctaError {
  color: theme('colors.danger');
  font-size: theme('fontSize.sm');
}
