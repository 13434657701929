.pdpImages {
  position: relative;

  .productImageFlags {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    row-gap: theme('space.2');
  }

  :global .slider__container {
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f3f1;
      user-select: none;

      @media screen and (min-width: 1024px) {
        max-width: 50%;
      }

      &, img {
        aspect-ratio: 3 / 4;
      }
    }

    &--with-thumbs {
      display: initial;

      .slider__slides {
        padding: 0;

        .swiper {
          padding-bottom: 10px !important;

          &-button {
            &-prev, &-next {
              opacity: 1;
              width: 30px;
              --swiper-navigation-size: 100px;
              background: hsla(0, 0%, 100%, 0.85);

              &::after {
                font-size: 15px;
                color: #000000;
              }
            }

            &-prev {
              left: 0;
            }

            &-next {
              right: 0;
            }

            &-disabled::after {
              opacity: 0.35;
            }
          }
        }
      }

      .slider__thumbs {
        height: unset;
        width: unset;

        @media screen and (max-width: 1023px) {
          display: none;
        }

        .swiper-slide {
          width: 50px !important;
          height: auto !important;
          border-bottom: 2px solid transparent;
          cursor: pointer;

          &.swiper-slide-thumb-active {
            border-color: theme('colors.primary');
          }

          & > div {
            pointer-events: none;
          }
        }

        .swiper {
          &-button {
            &-prev, &-next {
              width: 30px;
              --swiper-navigation-size: 30px;
              background: hsla(0, 0%, 100%, 0.85);

              &::after {
                font-size: 16px;
                color: theme('colors.black');
              }
            }

            &-prev {
              left: 0;
            }

            &-next {
              right: 0
            }
          }
        }
      }
    }
  }

  &.odpImages {
    :global .slider__container {
      .swiper-slide {
        background-color: transparent;
        max-width: unset;

        &, img {
          aspect-ratio: auto;
        }

        img + div {
          width: 100vw !important;
          background-color: theme('colors.white');
          left: -10% !important;
        }
      }

      &--with-thumbs {
        .swiper {
          padding-bottom: theme('space.4') !important;
        }

        .slider__thumbs {
          @media screen and (max-width: 1023px) {
            display: block;
          }

          .swiper-slide {
            width: calc((100% / 3.5) - 10px) !important;

            span[class^="pinch-zoom-image_iconContainer"] {
              display: none;
            }
          }
        }
      }
    }
  }
}

.magnifyContainer > div {
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid black;
  z-index: 999;
}

.sliderThumbsWrap {
  padding-left: 10%;
}
