.newsletterEmailWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 65%;
  font-size: 14px;
}

.newsletterInputWrap {
  padding: theme('space.2') 0;
  font-size: 14px;
}
