.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 150px;
  padding: theme('spacing.6') theme('spacing.7');
  aspect-ratio: 1.5 / 1;
  color: theme('colors.white');
  border-radius: 25px;
  margin: 0 -16px;
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.custNrWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: theme('spacing.3');
  border-left: 2px solid #ffffff;
  font-size: 16px;
  line-height: 21px;
}

.custNr {
  font-size: 18px;
  font-weight: bold;
}

.barcode {
  position: relative;
  margin-top: theme('spacing.8');

  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  overflow: hidden;
  padding-left: 20px;
}

.barcode svg {
  height: 69px;
  width: 359px;
}
