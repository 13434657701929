.orderModal {
  margin: 0 10%;
  font-size: 14px;
  color: theme('colors.fontColor');
}

.order {
  border-bottom: 1px dotted theme('colors.lightGrey');
}

.order,
.markdown {
  padding: theme('space.4');

  a {
    text-decoration: underline;
  }
}

.orderAddresses {
  display: flex;
  border: 1px dotted theme('colors.lightGrey');
  border-right: 0 none;
  margin: theme('space.4') -16px;

  & > div {
    width: 50%;
    padding: theme('space.4');
    border-right: 1px dotted theme('colors.lightGrey');
  }
}

.orderInfo {
  margin: theme('space.8') 0 theme('space.5');
}

.articleWrap {
  display: flex;
  align-items: flex-start;
  gap: theme('space.4');
  padding: theme('space.4') theme('space.4') 0;
  margin: theme('space.4') -16px 0;
  border-top: 1px dotted theme('colors.lightGrey');

  & > div {
    width: 20%;

    &:nth-child(2) {
      width: 40%;
    }
  }
}

.orderSubTotal {
  margin-top: theme('space.4');
  border-top: 1px dotted theme('colors.lightGrey');
  border-bottom: 1px dotted theme('colors.lightGrey');
}

.orderSum {
  display: flex;
  flex-direction: column;
  gap: theme('space.4');
  padding: theme('space.4');
  margin: 0 -16px;
  border-top: 1px dotted theme('colors.lightGrey');

  @media (min-width: 1024px) {
    max-width: 50%;
    margin-left: auto;
  }
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    text-align: right;
  }
}

.summaryLabel {
  @media (min-width: 1024px) {
    flex: 0 0 60%;
  }
}

.price {
  justify-content: flex-end;
  padding-left: theme('space.1');
  gap: theme('space.1');
  margin: 0;

  div {
    font-size: 14px;
  }
}

.priceInfo {
  display: block;
  text-align: end;
}

.savedPrice {
  color: theme('colors.success');
}

.itemDiscountDescriptionWrap {
  margin-top: theme('space.1');
}

.itemDiscountDescription {
  display: block;
  color: theme('colors.discountedPrice');
  font-weight: bold;
}

.bankAccountInformation {
  padding: theme('space.4');
  border: 1px dotted theme('colors.lightGrey');
}

.prepaymentDataRow {
  margin-bottom: theme('space.2');

  > span {
    display: block;
  }

  @media (min-width: 768px) {
    display: flex;
    margin-bottom: 0;

    > span {
      flex: 1 1 50%;
    }
  }
}
