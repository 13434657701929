.newsletter {
  .inputGroup,
  .submitWrapper {
    min-height: 28px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      display: block;

      .label {
        flex: unset;
        display: inline-block;
        margin-bottom: theme('space.1');
      }
    }

    .label {
      font-size: 14px;
      font-weight: 400;
      flex: 0 0 200px;
    }
  }

  .inputGroup {
    margin-bottom: theme('space.4');

    input[type="text"],
    input[type="email"] {
      padding: 0 theme('space.2');
      width: 100%;
      border: 1px solid theme('colors.gray400');
      min-height: 28px;
    }

    .radioLabel {
      position: relative;
      padding: theme('space.1') theme('space.5') theme('space.1') theme('space.9');
    }

    .radioInput {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 0;

      --tw-ring-color: transparent;
    }
  }

  .checkboxes {
    margin-left: 200px;

    @media (max-width: 767px) {
      margin-left: unset;
      margin-bottom: theme('space.6');
    }

    div {
      margin-bottom: theme('space.2');
    }
  }

  .submitWrapper {
    margin-top: theme('space.3');

    .label {
      color: theme('colors.gray400');
    }

    button {
      font-size: inherit;
      text-transform: uppercase;
    }
  }
}
