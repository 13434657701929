.accountBalanceWrapper {
  display: flex;
  justify-content: space-between;
}

.accountBalanceHeadline {
  width: 60%;
  display: flex;
  flex-direction: row;

  div > * {
    margin: unset;
  }
}

.accountBalanceImage {
  width: 40%;
}

.balance {
  margin-left: theme('spacing.1');
  font-size: theme('fontSize.lg');
  font-weight: theme('fontWeight.bold');
}
