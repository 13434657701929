.wrap {
  background-color: theme('colors.greenNeon');
  padding: theme('spacing.6') theme('spacing.6') theme('spacing.6') 80px;
  color: #000;
  position: relative;
  flex-grow: 1;
}
.title {
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: theme('spacing.1');
  line-height: 20px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: theme('spacing.3');
}
.content {
  font-size: 14px;
  line-height: 18px;
}

.checkmarkWrap {
  position: absolute;
  left: 24px;
  top: 18px;
  z-index: 99;
  background: white;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test {
  display: inline-block;
  transform: rotate(45deg);
  height: 16px;
  width: 10px;
  margin-top: -2px;
  border-bottom: 3px solid theme('colors.black');
  border-right: 3px solid theme('colors.black');
}
