.wrap {
    margin-left: 32px;
}

.changeButton {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
    display: inline-flex;
}
.changeButton:hover {
    text-decoration: none;
}


.searchWrap {
    border: 1px solid #0f202f;
}
.searchInput {
    border: none;
    width: 100%;
    border-bottom: 1px solid #0f202f;
    padding: 8px 32px;
    font-weight: 600;
    color: #0f202f;
    margin-bottom: -1px;
    font-size: 14px;
}
.searchInput::-webkit-search-decoration,
.searchInput::-webkit-search-cancel-button,
.searchInput::-webkit-search-results-button,
.searchInput::-webkit-search-results-decoration {
  -webkit-appearance:none;
  border-radius: 0;
}
.searchInput::placeholder {
    font-weight: 600;
    color: #0f202f;
    font-size: 14px;
}
.searchInput:focus {
    outline: none;
    box-shadow: none;
    border-color: #0f202f;
}

.searchResultsWrap {
    background-color: #F4F6F8;
    max-height: 280px;
    overflow: scroll;
}

.searchResultItem {
    padding: 12px 0;
    cursor: pointer;
    margin: 0 32px;
    position: relative;
    border-bottom: 1px solid #0f202f;
}
.searchResultItem:last-child {
    border-bottom: none;
}

.searchResultItemCity {
    font-weight: 600;
}

.searchResultItemCheckboxWrap {
    position: absolute;
    right: 8px;
    bottom: 8px;
}



.doneButtonWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.selectedItem {
    background: #fff;
    border: 1px solid #0f202f;
    padding: 8px 32px;
    margin-top: 8px;
}
.selectedItemZip {
    font-weight: 600;
}

.searchInputWrap {
    position: relative;
}

.removeButton {
    cursor: pointer;
    background: #0f202f;
    height: 22px;
    width: 22px;
    border-radius: 100px;
    position: absolute;
    text-transform: uppercase;
    right: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    top: 50%;
    transform: translate(0, -50%);
}

.searchButtonWrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 12px;
    border-left: 1px solid #0f202f;
}