.outfitProductBox {
  color: theme('colors.fontColor');
  font-size: 16px;
  line-height: 14px;
  position: relative;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 14px;

    &:nth-child(odd) {
      padding-left: theme('space.4');
      padding-right: theme('space.2');

      .outfitWishlistWrapper {
        right: theme('space.2');
      }
    }

    &:nth-child(even) {
      padding-right: theme('space.4');
      padding-left: theme('space.2');

      .outfitWishlistWrapper {
        right: theme('space.4');
      }
    }
  }
}

.outfitSliderProductBox {
  padding: 0 !important;
}

.outfitProductBoxInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  padding: theme('space.5') theme('space.2') 0;

  @media (max-width: 767px) {
    padding: theme('space.5') theme('space.1');
  }
}

.outfitSliderProductBoxInfo {
  & > div {
    display: none;

    @media (min-width: 768px) {
      display: block;
      width: 100%;
      font-size: inherit;
      text-align: left;
    }
  }

  & > a {
    font-size: theme('fontSize.sm');

    div:last-child {
      margin-left: calc(0px - theme('space.2'));
    }

    @media (min-width: 768px) {
      font-size: inherit;
      display: none;
    }
  }
}

.outfitProductBoxInfoLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: theme('space.1');
  font-weight: bold;
  font-size: theme('fontSize.sm');
  text-transform: uppercase;
  margin-top: theme('space.3');

  @media (max-width: 767px) {
    margin-top: theme('space.2');
  }
}
