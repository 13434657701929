.subHeadlineWrap {
  margin-top: theme('spacing.12');
  margin-bottom: theme('spacing.6');
  padding: 0 theme('spacing.5');

  @media (min-width: 1024px) {
    margin-top: 0;
    padding: 0;
  }
}

.orderItemBlock {
  margin-bottom: theme('spacing.5');
  padding-bottom: theme('spacing.5');
}
