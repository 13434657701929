.tabItem {
  padding-bottom: theme('spacing.2');
  font-size: 16px;
  position: relative;
}

.tabItem--active {
  border-bottom: 2px solid theme('colors.primary');
}
.tabItem--active button {
  font-weight: 500;
}

.counter {
  background: theme('colors.discountedPrice');
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  right: -12px;
  color: theme('colors.white');
  position: absolute;
  top: -12px;
}
