.pdpPadding {
  padding: theme('space[2.5]') 0;
}

.pdpWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.pdpInfoWrap {
  flex-grow: 1;
  max-width: 25%;
  padding-left: theme('space.3');

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 40%;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    max-width: 30%;
  }
}

.pdpImagesWrap {
  flex-grow: 3;
  max-width: 75%;
  padding-right: theme('space.3');

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 60%;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    max-width: 70%;
  }
}

.pdpInfoWrap,
.pdpImagesWrap {
  @media (max-width: 767px) {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
}

.shopUspsWrap {
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  @media (min-width: 1024px) {
    display: inline-flex;
    padding: theme('spacing.13') theme('spacing.4') 0;
  }
}
