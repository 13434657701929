.category {
  @media (max-width: 1023px) {
    flex-grow: 1;
  }
}

.categoryLink {
  display: block;
  text-align: center;
  font-size: theme('fontSize.sm');
  padding: theme('space.3');
  border: 1px solid theme('colors.primary');

  &:hover {
    outline: theme('colors.primary') solid 1px;
  }
}
