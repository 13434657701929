.text {
  overflow: hidden;
}
.text * {
  color: inherit;
}
.text * {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6,
    & > p,
    & > u,
    & > ol {
        margin-top: 8px;
    }
}

.text iframe {
  max-width: 100%;
}

.text ul {
  list-style: disc;
  margin-left: 20px;
  list-style-position: inside;
}

.text ol {
  list-style: decimal;
  margin-left: 20px;
  list-style-position: inside;
}

.alignment--left {
  text-align: left;
}
.alignment--center {
  text-align: center;
}
.alignment--right {
  text-align: right;
}
