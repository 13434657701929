.tableWrapper {
  padding-bottom: theme('space.5');
}

.tableHeadline {
  padding: theme('space.2') 0 theme('space.5');
}

.table {
  font-size: 14px;

  .tableTd {
    min-width: 50px;
    text-align: center;
    color: theme('colors.fontColor');
    padding: theme('space.1') theme('space.2');
    border: 5px solid theme('colors.white');
    background-color: theme('colors.inputBackground');
  }

  .firstColumn {
    position: sticky;
    left: 0;
    text-align: left;
    padding-right: theme('space.3');
    padding-left: 0;
    border-left: 0 none;
    background-color: theme('colors.white');
  }

  &.sizeAdvisorTable {
    font-size: 11px;

    .tableTd {
      line-height: 14px;
    }
  }
}
