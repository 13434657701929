.sectionWrap {
  margin-top: theme('space.3');
  padding: 0 theme('space.4');
  background-color: theme('colors.white');
}

.shopLinksWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  border-top: 8px solid theme('colors.white');
  border-bottom: 8px solid theme('colors.white');
}

.socialMediaLinksWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: theme('space.4');
  margin: theme('space.5') 0;
  background-color: transparent;

  img {
    border-radius: 50%;
  }
}

.extraLink {
  display: flex;
  align-items: center;
  gap: theme('space.4');
  padding: theme('space.3') 0;
  border-bottom: 1px solid theme('colors.gray100');

  &:last-child {
    border-bottom: 0 none;
  }
}

.shopLink {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 50%;
  max-width: 50%;
  height: 85px;
  margin-top: -1px;
  margin-left: -1px;
  padding: 0 theme('space.5');
  border-top: 1px solid theme('colors.gray100');
  border-left: 1px solid theme('colors.gray100');

  img {
    max-height: 60px;
    height: 100%;
    max-width: 150px;
    width: 100%;
    object-fit: contain;
  }

  @media (min-width: 768px) {
    flex-basis: 25%;
    max-width: 25%;
  }
}
