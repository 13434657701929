.wishlistWrap {
  position: absolute;
  top: 0;
  right: 0;
}

.wishlistButton {
  padding: theme('space.2');
}

.animateWishlistBtn {
  svg {
    animation: 1.5s ease-in-out heartBounce;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}

@keyframes heartBounce {
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1);
  }
}
