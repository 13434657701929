.searchFlyoutBackDrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
}

.searchFormWrapper {
  position: absolute;
  right: 0;
  transition: all 150ms ease;
}

.emilioSearchFormWrapper {
  z-index: 1;

  button[class="aa-SubmitButton"] {
    &:hover {
      color: theme('colors.orange');
    }
  }
}

.searchFormActive {
  transform: translateX(50%);
  right: 50%;
  width: 50%;
}

.navigationSearchFlyout {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 1280px;
  background: theme('colors.white');
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, theme('colors.white') 20%);

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    pointer-events: all;
  }

  &:before {
    transform: translateX(-100%);
  }

  &:after {
    transform: translateX(100%);
  }
}

.emilioNavigationSearchFlyout {
  z-index: 1;
}
