.hovering {
  display: block;
  padding: 10px;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, .7);
  text-align: center;
  z-index: 1;
  pointer-events: none;

  &.enGermanyHovering {
    min-height: 50px;
    position: static;
    text-align: left;
    max-width: 100% !important;
    flex-basis: 100% !important;
    padding: 0 !important;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .title,
    .sizes {
      display: inline;
    }

    .sizes {
      font-weight: 700;
      margin-left: theme('space.2');
      font-family: theme('fontFamily.gothamSans');
    }

    .colors {
      padding-top: theme('space.2');

      li:first-child {
        padding-left: 0;
      }
    }
  }
}

.title {
  margin-bottom: 2px;
}

.sizes {
  list-style: none;

  li {
    display: inline-block;

    &:hover {
      opacity: .6;
    }

    a {
      font-size: 14px;
      padding: 3px 4px 0;
      pointer-events: auto;
      color: theme('colors.black');
    }
  }

  .outOfStock {
    opacity: .4;
    padding: 0 4px;
  }
}

.colors {
  list-style: none;

  li {
    display: inline-block;
    padding: 8px 5px;
    vertical-align: top;

    &:hover {
      opacity: .6;
    }

    &.active {
      span {
        box-shadow: 0 0 0 1px transparent;
      }
    }

    a {
      pointer-events: auto;
    }

    span {
      display: block;
      width: 41px;
      height: 17px;
      box-shadow: 0 0 0 1px #d3d3d3 !important;
    }
  }

  :global {
    .reinweiss, .reinweiß, .weiss, .weiß {
      background-color: #fff !important;
    }

    .naturweiss, .naturweiß {
      background-color: #ede7df !important;
    }

    .elfenbein {
      background-color: #ded3c4 !important;
    }

    .beige {
      background-color: #c7b499 !important;
    }

    .braunbeige {
      background-color: #b8a17e !important;
    }

    .creme {
      background-color: #f6efce !important;
    }

    .ecru {
      background-color: #dbd1ad !important;
    }

    .sand {
      background-color: #cabc88 !important;
    }

    .lehm {
      background-color: #b8a560 !important;
    }

    .rostbraun {
      background-color: #bb5e09 !important;
    }

    .kupferbraun {
      background-color: #934a07 !important;
    }

    .mahagonibraun {
      background-color: #643205 !important;
    }

    .rehbraun {
      background-color: #b7793b !important;
    }

    .braun, .nussbraun {
      background-color: #8e5e2e !important;
    }

    .schokobraun {
      background-color: #593b1d !important;
    }

    .blassbraun {
      background-color: #97795b !important;
    }

    .tiefbraun {
      background-color: #69543f !important;
    }

    .schwarzbraun {
      background-color: #32281e !important;
    }

    .hellgrau {
      background-color: #f0f0f0 !important;
    }

    .mausgrau {
      background-color: #d3d3d3 !important;
    }

    .silbergrau {
      background-color: #b5b5b5 !important;
    }

    .grau, .kieselgrau {
      background-color: #979797 !important;
    }

    .zementgrau {
      background-color: #797979 !important;
    }

    .stahlgrau {
      background-color: #5a5a5a !important;
    }

    .granitgrau {
      background-color: #393939 !important;
    }

    .anthrazit {
      background-color: #181818 !important;
    }

    .schwarz {
      background-color: #000 !important;
    }

    .pastellgrün {
      background-color: #9fff9f !important;
    }

    .signalgrün {
      background-color: #5dff5d !important;
    }

    .minzgrün {
      background-color: #00bc00 !important;
    }

    .grasgrün, .grün {
      background-color: #008e00 !important;
    }

    .moosgrün {
      background-color: #005800 !important;
    }

    .tannengrün {
      background-color: #003200 !important;
    }

    .blassgrün {
      background-color: #959349 !important;
    }

    .khakigrün {
      background-color: #605f2f !important;
    }

    .olivgrün {
      background-color: #3c3c1e !important;
    }

    .minttürkis {
      background-color: #85ffe2 !important;
    }

    .türkis, .türkisgrün {
      background-color: #00eeb5 !important;
    }

    .cyangrün {
      background-color: #00a880 !important;
    }

    .petrolgrün {
      background-color: #008264 !important;
    }

    .petrol {
      background-color: #006464 !important;
    }

    .petrolblau {
      background-color: #006482 !important;
    }

    .cyanblau {
      background-color: #0089b4 !important;
    }

    .wasserblau {
      background-color: #00bbf6 !important;
    }

    .blautürkis {
      background-color: #71ddff !important;
    }

    .hellblau {
      background-color: #c8dcff !important;
    }

    .himmelblau {
      background-color: #aabeff !important;
    }

    .taubenblau {
      background-color: #8ca0fa !important;
    }

    .enzianblau {
      background-color: #697bff !important;
    }

    .blau, .brilliantblau {
      background-color: #3c50c8 !important;
    }

    .royalblau {
      background-color: #0000c8 !important;
    }

    .marineblau {
      background-color: #000096 !important;
    }

    .indigoblau {
      background-color: #000064 !important;
    }

    .saphirblau {
      background-color: #000032 !important;
    }

    .pastellgelb {
      background-color: #ffffa0 !important;
    }

    .zitronengelb {
      background-color: #efff53 !important;
    }

    .limonengelb {
      background-color: #dcf000 !important;
    }

    .gelb, .schwefelgelb {
      background-color: #ff0 !important;
    }

    .rapsgelb {
      background-color: #ffdc00 !important;
    }

    .maisgelb {
      background-color: #ffb400 !important;
    }

    .safrangelb {
      background-color: #e6be00 !important;
    }

    .currygelb {
      background-color: #c8a000 !important;
    }

    .ockergelb {
      background-color: #a07800 !important;
    }

    .pastellorange {
      background-color: #fde3a9 !important;
    }

    .gelborange {
      background-color: #ffd281 !important;
    }

    .sonnenorange {
      background-color: #ffb42d !important;
    }

    .mandarinenorange {
      background-color: #ffa675 !important;
    }

    .signalorange {
      background-color: #ff813b !important;
    }

    .blutorange {
      background-color: #dc5000 !important;
    }

    .orange, .reinorange {
      background-color: #ff8d2f !important;
    }

    .tieforange {
      background-color: #e66800 !important;
    }

    .rostorange {
      background-color: #b45000 !important;
    }

    .pastellrot {
      background-color: #ff9696 !important;
    }

    .lachsrot {
      background-color: #ff5a5a !important;
    }

    .signalrot {
      background-color: #ff1e1e !important;
    }

    .rot, .tomatenrot {
      background-color: #e10000 !important;
    }

    .feuerrot {
      background-color: #c80000 !important;
    }

    .blutrot {
      background-color: #aa1414 !important;
    }

    .karminrot {
      background-color: #8c1414 !important;
    }

    .rubinrot {
      background-color: #6e1414 !important;
    }

    .weinrot {
      background-color: #501414 !important;
    }

    .pink {
      background-color: #ffafdd !important;
    }

    .fuchsia {
      background-color: #ff1d9e !important;
    }

    .magenta {
      background-color: #a0005a !important;
    }

    .rosa {
      background-color: #ef8fff !important;
    }

    .orchidee {
      background-color: #c900ea !important;
    }

    .lila {
      background-color: #78008c !important;
    }

    .flieder {
      background-color: #bea9e9 !important;
    }

    .violett {
      background-color: #7344d0 !important;
    }

    .pflaume {
      background-color: #3c1e78 !important;
    }
  }
}
