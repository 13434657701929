.formWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: theme('spacing.2');
  margin: theme('spacing.4') 0;

  @media (max-width: 767px) {
    > * {
      flex-grow: 1;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    gap: theme('spacing.8');
    flex-direction: row;
  }
}

.heading {
  margin-bottom: theme('space.2');
}

.searchWrap {
  display: flex;

  --placeholder-focus-font-size: 11px;
  --placeholder-focus-transform: 12px;

  @media (max-width: 767px) {
    > div {
      flex-grow: 1;
    }
  }
}

.searchButton {
  background-color: theme('colors.primary');
  padding: 0 theme('spacing.4');
}

.citySelect {
  width: 100%;
  padding: theme('space.3');
  border-color: theme('colors.primary');

  &:focus {
    border-color: unset;
    box-shadow: unset;
  }
}

.storeFinder {
  position: relative;
}

.mapWrapper {
  position: relative;
  height: 250px;
  margin-bottom: theme('space.4');

  @media screen and (min-width: 768px) {
    height: 450px;
  }

  @media screen and (min-width: 1024px) {
    height: 600px;
  }
}

.spinnerWrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.results {
  width: 100%;
  padding-top: theme('space.7');
  background: theme('colors.white');
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    max-height: 100%;
    padding-right: theme('space.4');
    padding-left: theme('space.4');
  }
}

.storeListWrap {
  position: static;
}

.storeList {
  display: flex;
  flex-wrap: wrap;
  max-height: 407px;
  overflow-y: auto;
  padding-bottom: theme('space.7');
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  @media screen and (min-width: 768px) {
    max-height: 357px;
  }

  @media screen and (min-width: 1024px) {
    max-height: 538px;
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.overflowShadow {
  position: absolute;
  bottom: -3px;
  right: 17px;
  left: 17px;
  height: 4px;
  box-shadow: 0 0 20px theme('colors.black');
  border-top: 1px solid #bbb8b1;
}

.storeInfo {
  flex-basis: 100%;
  padding-top: theme('space.5');
  padding-bottom: theme('space.5');
  border-bottom: 1px solid theme('colors.darkBlue');
  font-size: theme('fontSize.sm');
  cursor: pointer;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: 640px) {
    flex: 1 0 50%;
  }

  @media screen and (min-width: 1024px) {
    flex-basis: 100%;
    padding-top: theme('space.4');
    padding-bottom: theme('space.4');

    &:last-child {
      padding-bottom: 0;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    &:first-child {
      padding-top: theme('space.5');
    }

    &:nth-of-type(2n) {
      padding-left: theme('space.5');
      border-left: 1px solid theme('colors.darkBlue');
    }

    &:nth-last-child(2):nth-of-type(odd) {
      border-bottom: 0;
    }
  }

  > span {
    display: block;
  }
}

.storeBookingLink {
  height: auto;
  min-height: 52px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;
  letter-spacing: normal;
  text-transform: none;
  text-align: center;
  background-color: theme('colors.storeavailability.appointment'); //Fallback
  background-color: var(--storeBookingLinkBgColo);
  color: theme('colors.white') !important;
  margin-top: theme('space.4');

  &:hover {
    opacity: 0.8;
  }
}

.separateSection {
  text-align: center;
  margin-top: theme('space.3');

  @media screen and (min-width: 768px) {
    text-align: left;
    margin: 0 0 theme('space.3') 0;
  }
}

.selectWrap {
  position: relative;

  @media screen and (min-width: 768px) {
    flex-basis: 295.5px;
  }
}

.select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  -webkit-print-color-adjust: exact;
  appearance: none;
  background-color: theme('colors.white');
  border: 1px solid theme('colors.primary');
  border-radius: 0;
  padding: theme('space.3') theme('space.10') theme('space.3') theme('space.3');
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  width: 100%;
}

.selectOptions {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding: theme('space.2') theme('space.3');
  background-color: theme('colors.white');
  border: 1px solid theme('colors.primary');
  border-top: 0;
}

.selectOptions[data-headlessui-state~="open"] {
  display: block;
}

.selectOption, .multiSelectOption {
  display: flex;
  align-items: center;
  column-gap: theme('space.2');
  padding-top: theme('space.1');
  padding-bottom: theme('space.1');
  cursor: pointer;
  width: max-content;
}

.checkbox {
  border: 1px solid theme('colors.inputBackground');
  background-color: theme('colors.inputBackground');
  color: theme('colors.primary');
  box-shadow: none;
  border-radius: 0;
  width: 20px;
  height: 20px;
  outline: none;
  margin-top: 1px;
  cursor: pointer;
}

.checkbox:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.selectOption[data-headlessui-state~="selected"] {
  font-weight: bold;
}

.multiSelectOption[data-headlessui-state~="selected"] {
  .checkbox {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.multiSelectOption[data-headlessui-state~="disabled"] {
  .checkbox {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: theme('colors.gray300') !important;
    background-color: theme('colors.gray100') !important;
  }
}

.label {
  display: flex;
  align-items: center;
  column-gap: theme('space.2');
  color: theme('colors.fontColor');
  flex-grow: 1;

  &:first-child {
    flex-shrink: 0;
  }
}

.assortment {
  white-space: nowrap;
}

.applyAssortments {
  margin-top: theme('space.1');
  padding: theme('space.2');
}
