.checkbox {
  border: 1px solid theme('colors.gray50');
  background-color: theme('colors.gray50');
  color: theme("colors.primary");
  box-shadow: none;
  border-radius: 0;
  width: 20px;
  height: 20px;
  outline: none;
  margin-top: 1px;
  cursor: pointer;
}

.checkbox:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.label {
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: theme('colors.fontColor');
  flex-grow: 1;
  cursor: pointer;
}

.facetValueWrapper {
  overflow: auto;
}

.termFacetValue {
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: theme('spacing.1') theme('spacing.1') theme('spacing.1') 0;

  &:not(:last-of-type) {
    margin-bottom: theme('spacing.3');
  }

  @media screen and (min-width: 1024px) {
    padding: 0;
  }

  :global {
    .blau {
      background-color: #3c50c8;
    }

    .grau {
      background-color: #979797;
    }

    .rot {
      background-color: #e10000;
    }

    .grün {
      background-color: #008e00;
    }

    .türkis {
      background-color: #00eeb5;
    }

    .orange {
      background-color: #ff8d2f;
    }

    .weiß {
      background-color: #fff;
    }

    .gelb {
      background-color: #ff0;
    }

    .schwarz {
      background-color: #000;
    }

    .beige {
      background-color: #c7b499;
    }

    .braun {
      background-color: #8e5e2e;
    }

    .violett {
      background-color: #7344d0;
    }

    .mehrfarbig {
      background: url('/images/bunt.jpg');
      background-size: cover;
    }

    .uni {
      background-color: #0f202f;
    }

    .gemustert {
      background-image: url('/images/print.png');
      background-size: cover;
    }

    .gestreift {
      background-image: url('/images/gestreift.png');
      background-size: cover;
    }

    .kariert {
      background-image: url('/images/kariert.png');
      background-size: cover;
    }
  }
}

.colorTile {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid theme('colors.lightGrey');
  border-radius: 50%;
  flex-shrink: 0;

  @media screen and (min-width: 1024px) {
    height: 16px;
    width: 16px;
  }
}

$columnCount: 4;
$gap: 4 + 0px;

$columnCountMobile: 5;
$gapMobile: 16 + 0px;

.tileFacetValues {
  display: flex;
  flex-wrap: wrap;
  gap: $gapMobile;

  @media screen and (min-width: 1024px) {
    gap: $gap;
  }
}

.tileFacetValue {
  flex-basis: calc(100% / $columnCountMobile - $gapMobile / $columnCountMobile * ($columnCountMobile - 1));
  border: 1px solid theme('colors.lightGrey');
  aspect-ratio: 1;

  &.checked {
    border: 1px solid theme('colors.black');
  }

  @media screen and (min-width: 1024px) {
    flex-basis: calc(100% / $columnCount - $gap / $columnCount * ($columnCount - 1));

    &:hover {
      border: 1px solid theme('colors.black');
    }
  }
}

.onlyLargeSizes,
.applyFilters {
  width: 100%;
  margin: $gapMobile 0;

  @media screen and (min-width: 1024px) {
    margin: theme('spacing.2') 0 0;
  }
}

.applyFilterButton {
  font-size: 14px;
  padding: 12px;
  width: 100%;
}

.sortByValue {
  width: 100%;
  margin-bottom: theme('spacing.4');
  text-align: left;

  &.isActive {
    font-weight: bold;
  }
}
