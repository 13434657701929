.item {
  font-size: 15px;
  font-weight: 600;
  padding: 24px 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.item:after {
  content: '';
  left: 8px;
  right: 8px;
  position: absolute;
  bottom: 0;
  height: 1px;
  background: theme('colors.lightGrey');
}

.iconWrap {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  position: relative;
}

.counter {
  background: #e30813;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  right: -3px;
  color: #fff;
  position: absolute;
  top: 0;
}

.label {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.chevronIconContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: theme('spacing.12');
}
