.popUpWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.checkMarkWrap {
  color: white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #00af7e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.labelWrap {
  text-align: center;
  font-size: 18px;
  color:  #0f202f;
}

.btnWrap{
  width: 100%;
  margin-top: 25px;

  button {
    margin-top: 10px;
  }
}

.popUpBtnWrap {
  margin-top: 10px;
  font-weight: 700;
  font-size: 16.25px;
  width: 100%;
  height: 45px;
  padding: 14px 18px;
  border: 1px solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.popUpBtnToCart {
  background-color: #0f202f;
  color: #fff;
}

.popUpBtnContShopping {
  background-color: #fff;
  color: #0f202f;
}
