.outfitProductWrap {
  display: flex;
  align-items: center;
  gap: theme('space.5');
  font-size: 12px;
  padding: theme('space.5') 0;
  border-top: 1px solid theme('colors.gray200');

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.oPName {
  display: flex;
  font-size: 16px;
  text-transform: uppercase;

  input {
    max-width: 16px;
    max-height: 16px;
    margin-top: 5px;
  }
}

.oPPrice {
  height: fit-content;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: wrap;
  gap: 0;
  font-size: 16px;
  margin: 0;
}

.oPTextArtNr {
  color: theme('colors.gray600');
}

.oPToProduct {
  text-decoration: underline;
}

.oPSizesWrap {
  display: flex;
  flex-wrap: wrap;
  gap: theme('space.2');
}

.oPSizeBtn {
  width: calc(20% - 8px);
  height: auto;
  font-size: 14px;
  font-weight: normal;
  padding: theme('space.2');
  border: 1px solid theme('colors.gray100');
  transition: all 200ms ease-in-out;

  &:hover:not(&.oPSizeBtnSelected):not(&.oPSizeBtnDisabled) {
    border-color: theme('colors.gray300');
  }

  &.oPSizeBtnSelected {
    border-color: theme('colors.primary');
  }

  &.oPSizeBtnDisabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: url('/images/size-disabled-pattern.jpeg') repeat 50% hsla(0, 0%, 95.7%, 0.3);
  }
}

.opSizeError {
  color: theme('colors.danger');
  padding: theme('space.3');
  margin-top: theme('space.7');
  border-radius: theme('space.1');
  border: 1px solid theme('colors.danger');
}
