.raffleFullWidth {
  margin: 0 theme('spacing.2');
}

.birthdayWidth {
  width: 75%;
}

.headline {
  margin-bottom: theme('spacing.4');
}

.markdownGuidelines {
  font-size: theme('fontSize.sm');
  margin: 0;

  & > * {
    margin: 0;
  }
}

.radioInputLabel {
  position: relative;

  input {
    width: 20px;
    height: 20px;
    margin: -2px theme('space.3') 0 0;
  }
}

.quizErrorMsg {
  color: theme('colors.danger');
  font-size: 13px;
  font-weight: 400;
}
