.headlineWrap {
  line-height: 24px;
  margin-bottom: theme('spacing.7');

  p {
    margin-bottom: theme('spacing.1');
  }
}

.contentWrap {
  display: flex;
  flex-wrap: wrap;
}

.overviewLink {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: theme('spacing.5') 0;
  border-top: 1px dotted theme('colors.lightGrey');

  &:nth-child(odd) {
    width: calc(50% - 24px);
    margin-right: theme('spacing.6');
  }
}

.overviewLinkImage {
  width: 50%;
}

.overviewLinkHeadline {
  display: flex;
  align-items: baseline;
  margin-bottom: theme('spacing.4');

  div {
    margin-right: theme('spacing.2');
    max-height: 18px;
    filter: contrast(.1);
  }
}

.emilioOverviewLinkHeadline {
  a:hover {
    color: theme('colors.orange');
  }
}

.overviewLinkText {
  margin-bottom: theme('spacing.4');
}

.overviewLinkTextLink {
  display: block;
  margin-bottom: theme('spacing.4');
  color: theme('colors.gray400');
  text-decoration: underline;
}
