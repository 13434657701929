.loginWrapper {
  display: flex;
  justify-content: space-between;
  padding: theme('spacing.5') 0;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
}

.loginBoxWrapper {
  @media (min-width: 1024px) {
    width: 50%;
    max-width: 575px;
    margin-right: theme('spacing.12');
  }
}

.helpText {
  color: theme('colors.gray300');
  font-size: 12px;
  line-height: 16px;
}

.loginBox {
  height: fit-content;
  border: 1px solid theme('colors.gray100');
  padding: theme('spacing.8');

  &:first-child {
    margin-bottom: theme('spacing.10');
  }

  .markdownHeadline {
    margin-bottom: theme('spacing.5');
  }

  a {
    font-size: 12px;
  }

  @media (max-width: 1023px) {
    border: 0 none;
    border-bottom: 1px solid theme('colors.gray100');
    padding: theme('spacing.8') 0;

    &:first-child {
      margin-bottom: 0;

      .markdownHeadline {
        margin-bottom: theme('spacing.8');
        padding: 0 theme('spacing.5');
      }
    }

    .markdownHeadline {
      text-align: center;
    }
  }
}

.loginBackBtn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
