.wrap {
  padding-top: theme('spacing.7');
  border-top: 1px solid theme('colors.primary');
  margin-left: 80px;
  font-size: 12px;
  flex-grow: 1;

  @media (min-width: 1024px) {
    padding: theme('spacing.4') 0;
    border: none;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
  font-size: 14px;
  gap: 4px;

  @media (min-width: 1024px) {
    text-align: right;
    white-space: nowrap;

    span:first-of-type {
      flex: 0 0 75%;
    }
  }
}

.sumRow {
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
}
.row + .sumRow {
  margin-top: theme('spacing.5');
}

.subNoteRow {
  text-align: right;
  line-height: 20px;
  margin-top: -2px;
}
