.loadingSpinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(165, 172, 177, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
