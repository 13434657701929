.wrap {
  color: theme('colors.fontColor');
}

.title {
  font-size: 14px;
}

.artNum {
  display: block;
  margin-top: theme('spacing.2');

  @media (min-width: 768px) {
    display: inline;
    padding-left: theme('spacing.8');
  }
}

.formWrap {
  display: flex;
  flex-direction: column;
  gap: theme('spacing.2');
  margin: theme('spacing.4') 0;
  padding: theme('spacing.4') 0 theme('spacing.4');
  border-top: 1px dotted theme('colors.gray500');
  border-bottom: 1px dotted theme('colors.gray500');

  @media (min-width: 768px) {
    gap: theme('spacing.8');
    flex-direction: row;
    align-items: center;
  }
}

.searchWrap {
  display: flex;
}

.searchButton {
  background-color: theme('colors.primary');
  padding: 0 theme('spacing.4');
}

.resultsWrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: theme('spacing.8');
  margin-bottom: theme('spacing.4');
  min-height: 90px;
  max-height: 140px;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (min-width: 768px) {
    min-height: 300px;
    max-height: 380px;
  }
}

.spinnerWrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.noteWrap {
  padding-top: theme('spacing.4');
  border-top: 1px dotted theme('colors.gray500');
  font-size: 12px;
}

.key {
  display: flex;

  > div {
    margin-right: theme('spacing.4');
  }
}

.tile {
  display: block;
  width: 40px;
  height: 25px;
}

.available {
  background-color: theme('colors.storeavailability.available');
}

.onlineonly {
  background-color: theme('colors.storeavailability.onlineOnly');
  background-image: theme('colors.storeavailability.onlineOnly');
  background-size: 8px 8px;
}

.notavailable {
  background-color: theme('colors.storeavailability.notAvailable');
}
