.backDrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}

.customDropdown {
  width: 100%;
  border: 1px solid theme('colors.primary');
}

.isDropdownOpen {
  position: relative;
  z-index: 999;
  border: 1px solid theme('colors.primary') !important;
}

.customDropdownToggleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: theme('space.1');
}

.customDropdownIcon {
  transform: rotate(90deg);
}

.customDropdownIconRotate {
  transform: rotate(-90deg);
}

.customDropdownContent {
  display: none;
  max-height: 250px;
  overflow-y: auto;
  padding: theme('space.1');
}

.isCustomDropdownOpen {
  display: block;
}

.customDropdownContentFlyout {
  width: calc(100% + 2px);
  position: absolute;
  top: calc(100% - 4px);
  left: -1px;
  background: theme('colors.white');
  border: 1px solid theme('colors.primary');
  border-top: 0 none;
}

.customDropdownOptionBtn {
  display: block;
  width: 100%;
  padding: theme('space.1');
  text-align: left;
  border-top: 1px dotted theme('colors.gray400');

  &:hover {
    font-weight: bold;
  }
}

.customDropdownOptionBtnDisabled > * {
  color: theme('colors.gray400');
  text-decoration: line-through;
  cursor: not-allowed;
}

.customDropdownOptionBtnSelected {
  font-weight: bold;
}
