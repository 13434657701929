.text {
  div {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    padding-bottom: theme('spacing.1');
    height: fit-content;
    -webkit-line-clamp: inherit;
  }

  * {
    color: inherit;
  }
}
