.categorySlider {
  overflow-x: scroll;
  white-space: nowrap;
  margin-bottom: theme('space.3');

  $shadow-width: 30px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: theme('space.2');
    width: $shadow-width;
    z-index: 2;
  }

  &:before {
    left: calc(max(min(var(--scroll-left, 0px), $shadow-width), 0px) - $shadow-width);
    background: linear-gradient(90deg, theme('colors.white') 0%, theme('colors.white' / 0%) 100%);
  }

  &:after {
    right: calc(max(min(var(--scroll-right, 0px), $shadow-width), 0px) - $shadow-width);
    background: linear-gradient(270deg, theme('colors.white') 0%, theme('colors.white' / 0%) 100%);
  }

  ul {
    display: flex;
    gap: theme('space[2.5]');
    padding-bottom: theme('space.2');

    li {
      a {
        padding: theme('space.1') theme('space.4');
        line-height: 20px;
        background: theme('colors.gray50');
        border: 1px solid theme('colors.gray100');
        font-weight: 400;
        font-size: theme('fontSize.base');
        display: block;
        text-decoration: none;
      }
    }
  }
}
