.summaryWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: theme('space.3');
  font-size: 14px;
  padding: theme('space.5') 0;
  border-top: 1px solid theme('colors.lightGrey');

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.summaryBlockWrap {
  width: 100%;
  flex-basis: 50%;

  @media (max-width: 767px) {
    width: 100%;
    flex-basis: 100%;

    &:first-child {
      order: 2;
    }
  }

  textarea {
    padding: theme('space.2');

    &:focus {
      border: 1px solid theme('colors.gray400');
    }
  }
}

.commentOnOrderLabel {
  display: block;
  margin-bottom: theme('space.1');
  opacity: 0.6;
}

.totalRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: theme('space.1');
  font-size: 14px;

  & > div {
    width: 40%;
    text-align: right;
  }

  & > span {
    width: 60%;
  }

  @media (min-width: 768px) {
    text-align: right;
  }
}

.removeDiscountBtn {
  margin-right: theme('space.1');
}

.price {
  justify-content: flex-end;
  font-size: 14px;
  margin: 0;
}

.totalPrice {
  font-size: 18px;
  font-weight: bold;
  margin-top: theme('space.4');
}

.savedPrice {
  font-size: 16px;
  color: theme('colors.success');
}

.shippingCostsNote {
  text-align: right;
}
