.headingWrap {
  display: inline-flex;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: theme('spacing.6');
  padding-bottom: theme('spacing.3');
  padding-right: theme('spacing.4');
  border-bottom: 1px solid theme('colors.primary');
}

.balanceWrap {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  padding-left: theme('spacing.3');
  border-left: 3px solid theme('colors.primary');
  font-size: 16px;
  line-height: 21px;
}

.balance {
  font-size: 18px;
  font-weight: bold;
}
