.sticky {
  top: 100px !important;
  
  width: 100%;
}

.contentSidebarNav {
  & > ul > li {
    margin-bottom: theme('space.3');
  }
}


.hiddenNav {
  opacity: 0;
  pointer-events: none;
}