.voucherWrap {
  padding: theme('space.5') 0;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start !important;
    gap: theme('space.2');
  }
}

.sectionWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  padding-bottom: theme('space.4');
}

.voucherInstructionWrap {
  margin-right: auto;
  margin-bottom: theme('space.2');
  padding-right: theme('space.2');
}

.voucherInstruction {
  font-size: 14px;

  @media (max-width: 767px) {
    font-size: 13px;
  }
}

.voucherFormWrap {
  @media (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap;
  }

  button,
  input {
    font-size: 14px;
  }

  button {
    max-height: 42px;
    margin-left: theme('space.2');
    z-index: 2;

    @media (min-width: 768px) {
      padding: theme('space.3') theme('space.10');
    }
  }

  input {
    &:focus {
      outline: 0 none;
      box-shadow: none;
      border-color: theme('colors.gray500');
    }
  }
}

.voucherInputWrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  gap: theme('space.2');

  @media (max-width: 767px) {
    flex-grow: 1;

    & > div {
      width: 100%;
    }
  }
}

.voucherInput {
  width: 100%;

  @media (min-width: 768px) {
    max-width: 230px;
  }
}

.voucherApplyWrap {
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    & > button {
      flex-grow: 1;
    }
  }
}

.pinWrapper, .pinLabel {
  overflow: hidden;
  transition: transform 0.2s ease;
  opacity: 0;
  width: 0;
  z-index: 1;
  transform: translateX(100%);
  position: absolute;

  &.active {
    opacity: 1;
    width: auto;
    min-width: fit-content;
    margin-left: theme('space.2');
    transform: translateX(0);
    position: static;
  }

  @media (max-width: 767px) {
    transition: none;
    margin-left: 0 !important;
    flex-grow: 1;

    & > input {
      width: 100%;
    }
  }
}

.voucherError {
  position: absolute;
  display: block;
  padding-top: theme('space.1');
  color: theme('colors.danger');
}

.voucherLabel, .pinLabel {
  font-weight: 700;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: theme('space.1');
  }
}

.voucherInfoButton {
  height: auto;
  padding: 0 !important;
  margin-right: theme('space.3');
  margin-left: 0 !important;
}

.voucherInfoMarkdown {
  color: theme('colors.fontColor');
  font-size: 14px;
}

.voucherHint {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 12px 20px;
  background-color: theme('colors.success');
  color: theme('colors.white');
  width: calc(100% - 122px);

  &.hasError {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: theme('colors.orange');
  }
}
