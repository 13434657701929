.newsletterResubscriptionWrapper {
  border-top: 1px dotted theme('colors.gray200');
  border-bottom: 1px dotted theme('colors.gray200');
  margin: theme('space.3') 0;

  .newsletterResubscriptionForm {
    padding: theme('space.3') 0 theme('space.6');
    font-size: theme('fontSize.base');
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .optionsWrapper {
      margin-bottom: theme('space.3');
    }

    .required::after {
      content: '*';
    }

    .submitSubscriptionButton {
      text-transform: uppercase;
      font-size: 16px;
    }

    @media (min-width: 768px) {
      padding: theme('space.6') 0;
      display: inline-grid;
      grid-auto-rows: max-content;
      grid-gap: theme('space.3') theme('space.15');
      grid-template-columns: max-content 1fr;
      width: 80%;

      .optionsWrapper {
        margin-bottom: 0;
      }

      .optionsLabel {
        margin-top: 0;
      }

      .label {
        align-self: center;
        margin-top: 0;
      }
    }

    .mandatory {
      color: theme('colors.gray400');
    }

    @media (max-width: 767px) {
      label[for="email"] + div {
        margin-bottom: theme('space.3');
      }
    }
  }
}
