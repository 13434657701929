.facetsWrap {
  display: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: theme('spacing.4');
  margin-bottom: theme('spacing.4');
  font-size: 14px;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
}

.facets {
  display: flex;
  flex-wrap: wrap;
  gap: theme('spacing.4');
  flex-grow: 1;
}

.filterDropdownBackDrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.facetWrap {
  margin-bottom: theme('spacing.2');
  border-bottom: 2px solid theme('colors.lightGrey');
}

.facetButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: theme('spacing.1');
  padding-bottom: theme('spacing.1');
  text-align: left;
  z-index: 101;
  position: relative;
}

.buttonIcon {
  transform: rotate(90deg);

  &.open {
    transform: rotate(-90deg);
  }
}

.facetItemsWrap {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  padding: theme('spacing.3');
  background-color: theme('colors.white');
  border: 1px solid theme('colors.lightGrey');
  display: none;

  &.open {
    display: block;
    z-index: 102;
    border-top: 2px solid theme('colors.black');
  }
}

.facetItemWrap {
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.buttonWrap {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  z-index: 101;
}

.showAllButton {
  padding-top: theme('spacing.1');
  padding-bottom: theme('spacing.1');
  text-align: right;
}

.clearRefinementsButton {
  margin-top: theme('spacing.3');
}
