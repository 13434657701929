.filterWrap {
  display: block;
  margin-bottom: theme('spacing.3');

  @media screen and (min-width: 1024px) {
    display: none;
  }

  button {
    font-size: theme('fontSize.base');
  }
}

.filterModalBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(15, 32, 47, 0.25);
}

.filterModal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  height: 0;
  padding: theme('spacing.2') theme('spacing.6') theme('spacing.6');
  background-color: theme('colors.white');
  opacity: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &.isOpen {
    bottom: 0;
    height: calc(100vh - 58px);
    opacity: 1;
    z-index: 101;

    @supports (height: 100dvh) {
      height: calc(100dvh - 58px);
    }
  }
}

.facetHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-bottom: theme('spacing.4');
  padding: theme('spacing.3') 0 theme('spacing.4');
  border-bottom: 1px solid theme('colors.gray100');
  font-weight: bold;
}

.facetBackBtn {
  background-color: theme('colors.gray50');
  padding: theme('spacing.2');
  border-radius: 50%;
  color: theme('colors.fontColor');
}

.facetAttribute {
  flex-grow: 1;
  text-align: center;
}

.closeButton {
  padding: theme('spacing.1');
  margin-left: auto;
  margin-right: -4px;
}

.closeIcon {
  height: 24px;
  width: 24px;
  position: relative;
}

.closeIcon:before {
  content: '';
  position: absolute;
  height: 20px;
  background: #888;
  width: 1px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.closeIcon:after {
  content: '';
  height: 20px;
  width: 1px;
  position: absolute;
  left: 50%;
  background: #888;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.facetsWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: 100%;
  background-color: theme('colors.white');
  transition: all 300ms ease-in-out;
}

.facet {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: theme('spacing.3') 0;
  border-bottom: 1px solid theme('colors.gray100');
  font-weight: bold;
}

.facetValuesWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: theme('space.4');
  transition: left 300ms ease-in-out;
  background-color: theme('colors.white');
}

.facetValueWrap {
  display: flex;
  flex-direction: column;
}

.isHidden {
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}

.isActive {
  left: 0;
  transition: left 200ms ease-in-out;
}
