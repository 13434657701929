.shippingMethodsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: theme('space.5');

  @media (max-width: 1023px) {
    flex-direction: column;
  }
}

.shippingMethodBoxWrapper {
  flex: calc(25% - 40px);
}

.shippingMethodBox {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 12px;
  padding: theme('space.9') theme('space.3') theme('space.6');
  box-shadow: 0 0 0 1px theme('colors.lightGrey');
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: theme('space.3');
    right: theme('space.3');
    width: 18px;
    height: 18px;
    background-color: theme('colors.gray50');
    background-position: center;
    background-repeat: no-repeat;
  }

  @media (hover: hover) {
    &:hover:before {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACISURBVHgBtdDRDUAwEAbgOwNghE6AVUzASEZgAjGBmEBHMIINzrVFpKK0if+p6d3X9goQGPRpTkWREtCollEAKghw+HTjFSFgty6yxhCk9iNV8EUacmFORD7bB7iQhgg0mYFpPPAbUkHTmLX8UxUvJTeWjHoXOqGF98IzuiUWWcPzEj+1hb+yAXODSJq4FOkKAAAAAElFTkSuQmCC');
      opacity: 0.5;
    }
  }

  &.isChecked {
    box-shadow: 0 0 0 2px theme('colors.primary');

    &:before {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACISURBVHgBtdDRDUAwEAbgOwNghE6AVUzASEZgAjGBmEBHMIINzrVFpKK0if+p6d3X9goQGPRpTkWREtCollEAKghw+HTjFSFgty6yxhCk9iNV8EUacmFORD7bB7iQhgg0mYFpPPAbUkHTmLX8UxUvJTeWjHoXOqGF98IzuiUWWcPzEj+1hb+yAXODSJq4FOkKAAAAAElFTkSuQmCC');
    }

    &:hover:before {
      opacity: 1;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    padding: theme('space.5');

    &:before {
      top: 50%;
      right: theme('space.5');
      transform: translateY(-50%);
    }
  }
}

.shippingMethodBoxWrap {
  display: none;
}

.isFree {
  color: theme('colors.success');
}

.shippingMethodBoxTextWrap {
  @media (max-width: 1023px) {
    margin-left: theme('space.5');
    margin-right: theme('space.7');
  }
}

.shippingMethodBoxLabel {
  font-weight: bold;
  margin: theme('space.3') 0;

  @media (max-width: 1023px) {
    margin: 0;
  }
}

.addressFormWrap {
  width: 100%;
  margin-top: theme('space.4');
  order: 2;

  @media (max-width: 1023px) {
    margin: 0;
    order: 1;
  }
}

.addressFormHeadline {
  display: block;
  text-align: center;
  font-weight: bold;
  margin-bottom: theme('space.4');
}

.addressFormText {
  display: block;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: theme('space.6');
}

.errorMessage {
  color: theme('colors.danger');
  font-size: 13px;
  font-weight: 400;
}
