.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.newsletterHeadline {
  width: 100%;
  padding: 20px 48px;
  display: flex;
  align-items: center;

  * {
    color: inherit;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 575px) {
    padding: 20px;

    h1,
    .h1 {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

.newsletterHeadlineVoucher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  padding: 10px;
  margin-left: 10%;
  border-radius: 50%;

  span {
    font-size: 14px;
  }
}

.newsletterEmailWrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  border: 2px solid theme('colors.gray400');
  margin: theme('space.3') auto 0;
  text-align: left;
}

.newsletterEmailInput {
  width: calc(100% - 45px);

  input {
    background-color: theme('colors.white');
  }
}

.newsletterEmailButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  background: theme('colors.primary');
}
