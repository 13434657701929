.searchWrapper {
  width: 100%;
  background-color: theme('colors.white');
  padding: theme('space.4') theme('space.4') 0;
  position: relative;
}

.searchFormWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: theme('colors.gray50');
  border: 1px solid theme('colors.gray100');

  & > div {
    width: 100%;
  }
}

.searchBackBtn {
  position: absolute;
  left: 28px;
  height: 48px;
  width: 28px;
  background-color: theme('colors.gray50');
  z-index: 1;
}

.searchIconAligned {
  left: auto;
  right: 32px;
  width: 28px;
  background-color: theme('colors.gray50');
}

.searchFlyout {
  width: 100%;
  height: 0;
  transition: all 300ms ease-in-out;
  background-color: theme('colors.white');
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  padding: theme('space.4');
  opacity: 0;
  pointer-events: none;
}

.showSearchFlyout {
  height: calc(80vh - 65px);
  opacity: 1;
  pointer-events: auto;
  overflow: auto;
}
