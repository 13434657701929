.headline {
  margin: theme('spacing.4') 0;
}

.acceptTosWrap {
  display: flex;
  column-gap: 10px;
}

.errorMsgColor {
  color: theme('colors.danger');
}

.markdownGuidelines {
  font-size: 14px;
  margin: 0;

  & > * {
    margin: 0;
  }
}

.wrapperRadioBtn {
  width: 75%;

  label {
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    text-align: left;

    & + .radio {
      margin-top: theme('spacing.3');
    }

    input {
      display: none;

      & + span {
        display: inline-block;
        position: relative;
        padding: 0 theme('spacing.5') 0 theme('spacing.9');

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 50%;
          margin-right: theme('spacing.1');
          width: 22px;
          height: 22px;
          border: 1px solid theme('colors.gray200');
          background: theme('colors.white');
        }

        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background: #222;
          position: absolute;
          border-radius: 50%;
          top: 3px;
          left: 3px;
          opacity: 0;
          transform: scale(0, 0);
          transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
        }
      }

      &:checked + span:after {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  }
}

.errorMsg {
  font-size: 13px;
  font-weight: 400;
  color: theme('colors.danger');
}

.phoneWrap {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
