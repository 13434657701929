.footer {
  @media (max-width: 1023px) {
    margin-bottom: theme('space.14');
  }
}

.emilioFooter {
  font-weight: 400;
}

.footer1FulWidthWrap,
.footer2FulWidthWrap,
.footer3FulWidthWrap {
  width: 100vw;
  padding: 0 theme('space.4');
  background-color: theme('colors.gray50');

  .footerFirstSectionWrap,
  .footerSecondSectionWrap,
  .footerThirdSectionWrap {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.footer2FulWidthWrap {
  background-color: theme('colors.white');
}

.footer3FulWidthWrap {
  background-color: theme('colors.primary');
}

.footerFirstSectionWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: theme('colors.gray50');
  padding: theme('space.9') 0;
  position: relative;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
}

.enGermanyFooterFirstSectionWrap {
  padding: theme('space.18') 0;

  .sectionWrap:first-child {
    text-align: left;

    h1, h2, h3, h4, h5 {
      line-height: 30px;
    }

    * {
      color: inherit;
    }
  }

  .newsletterEmailWrapper {
    border: 0 none;

    div[class^="input-text_inputWrap"] {
      height: 44px;

      input {
        color: theme('colors.enGermanyPrimary');
      }

      div[class^="input-text_placeholder"] {
        font-family: theme('fontFamily.theSerif');
        color: theme('colors.enGermanyPrimary');
      }
    }

    .newsletterEmailButton {
      width: auto;
      font-size: 14px;
      color: theme('colors.white');
      background-color: theme('colors.enGermanyPrimary');
      padding: 0 theme('space.4');
    }
  }

  .socialMediaIconsWrap {
    li {
      margin: 0 theme('space.2');
    }
  }

  @media (max-width: 1023px) {
    padding: theme('space.10') 0;
    gap: theme('space.4');

    .sectionWrap:first-child {
      text-align: center;
    }
  }
}

.sectionWrap {
  text-align: center;
  padding-left: theme('space.6');
  z-index: 1;
  width: calc(100% / 3);

  &:first-child {
    padding-left: 0;
    text-wrap: nowrap;
    @media screen and (min-width: 1025px) {
      text-align: left;
    }
  }

  @media (max-width: 1023px) {
    width: 100% !important;

    &:nth-child(odd) {
      padding: theme('space.4') 0;
    }
  }
}

.newsletterEmailWrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  border: 2px solid theme('colors.gray400');
  margin: theme('space.3') auto 0;
  text-align: left;
}

.newsletterEmailInput {
  width: calc(100% - 45px);

  input {
    background-color: theme('colors.white');
  }
}

.newsletterEmailButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  background: theme('colors.primary');
}

.socialMediaIconsWrap {
  display: flex;
  justify-content: center;
  margin-top: theme('space.4');

  li {
    margin: 0 theme('space.4');

    img {
      border-radius: 50%;
    }
  }
}

.footerTopColumnsWrap {
  display: flex;
  justify-content: flex-start;
}

.footerFirstColumnWrap {
  width: 20%;
  padding: 0 !important;

  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      margin-top: theme('space.1');
      margin-right: theme('space.1');
    }
  }
}

.footerColumnsWrap {
  display: flex;
  justify-content: space-between;
  width: 80%;

  & > div {
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

.footerColumn {
  border-right: 1px solid theme('colors.lightGrey');
  padding: 0 2% theme('space.5') 5%;

  &:last-child {
    border-right: 0 none;
  }

  > h5 {
    padding-top: theme('space.11');
    margin-bottom: theme('space.3');
  }

  a {
    display: block;
    font-size: 14px;
    color: theme('colors.darkGrey');
    line-height: theme('space.5');
    margin-bottom: theme('space.1');
    word-break: break-word;
    word-wrap: break-word;

    &:hover {
      font-weight: 700;
    }
  }

  ul {
    width: fit-content;
  }

  @media (max-width: 1023px) {
    padding: theme('space.3') theme('space.5');

    > h5 {
      display: none;
    }
  }
}

.emilioFooterColumn {
  a:hover {
    font-weight: inherit;
    color: theme('colors.orange');
  }
}

.footerPartner {
  > h5 {
    margin-top: theme('space.5');
    margin-bottom: theme('space.3');
  }
}

.footerFullWidthColumnWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid theme('colors.lightGrey');
  padding: theme('space.3') 0;
}

.footerTrustedShop {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: theme('space.2');
  }
}

.footerTrustedShopMobile {
  border-top: 1px solid theme('colors.lightGrey');
  padding: theme('space.3') theme('space.5');
}

.footerPaymentMethodsWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h5 {
    margin-right: theme('space.4');
  }

  @media (max-width: 1023px) {
    justify-content: center;

    > h5 {
      display: none;
    }
  }
}

.footerPaymentMethods {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  :not(.footerPaymentName):first-child {
    margin-right: theme('space.2');
  }

  strong {
    font-size: 14px;
    margin-right: theme('space.2');
    color: theme('colors.fontColor');
  }

  @media (max-width: 1023px) {
    justify-content: center;
    flex-wrap: wrap;
    padding: theme('space.3') theme('space.5');
  }
}

.footerCopyRight {
  text-align: center;
  background-color: theme('colors.gray50');
  position: relative;
  font-size: 14px;
  padding: theme('space.4');

  a {
    text-decoration: underline;
  }

  & > div {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: theme('colors.gray50');
  }
}

.footerThirdSectionWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: theme('colors.primary');
  padding: theme('space.4') 0;
  color: theme('colors.white');
  font-size: 12px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.enGermanyFooterThirdSectionWrap {
  font-size: 14px;
  font-family: theme('fontFamily.theSerif');

  img {
    padding: theme('space.3') 0;
  }

  & > div:last-child {
    a {
      text-decoration: none;
      font-weight: 600;
      padding-right: theme('space.3');
      position: relative;

      &:after {
        content: '';
        width: 2px;
        height: 14px;
        background-color: theme('colors.white');
        position: absolute;
        right: 0;
        bottom: theme('space.1');
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    padding: theme('space.8') 0;
    margin-top: theme('space.3') !important;

    & > div:first-child {
      display: none;
    }
  }
}
