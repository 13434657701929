.suggestedSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: theme('space.2') theme('space.1');
  border-bottom: 1px solid theme('colors.gray200');
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border-color: theme('colors.primary');

    button[class^="suggested-search_removeSearchBtn"] {
      opacity: 1;
    }
  }
}

.suggestedSearchValue {
  width: 100%;
  text-decoration: none;
}

.removeSearchBtn {
  transition: all 0.2s ease-in-out;
  padding: 0 theme('space.2');
  font-weight: 500;

  @media (min-width: 1024px) {
    opacity: 0;
  }
}
