.mobileSearchForm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 250px;
  padding: theme('space.2') theme('space.3');

  button {
    margin: theme('space.1') theme('space.1') 0;
  }
}

.mobileInputWrapper {
  width: 100%;
  padding: theme('space.1') theme('space.1') theme('space.1') theme('space.9');
}

.mobileSearchIcon {
  position: absolute;
  left: 28px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobileSearchInput {
  width: 100%;
  padding: 0;
  border: 0 none;
  font-size: 14px;
  text-transform: uppercase;
  background-color: theme('colors.gray50');

  &:focus {
    box-shadow: none;
    border-color: theme('colors.gray200');
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.mobileSearchFlyoutContentWrapper {
  width: 100%;
  height: 0;
  transition: all 300ms ease-in-out;
  background-color: theme('colors.white');
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  padding: theme('space.4');
  opacity: 0;
  pointer-events: none;
}

.searchForm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 250px;
  padding: theme('space.2') theme('space.3');
  background-color: theme('colors.gray50');

  button {
    margin: 0 theme('space.1');
  }
}

.inputWrapper {
  width: 100%;
  margin-left: theme('space.1');

  input {
    width: 100%;
    padding: 0;
    border: 0 none;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid theme('colors.gray200');
    background-color: theme('colors.gray50');

    &:focus {
      box-shadow: none;
      border-color: theme('colors.gray200');
    }
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.searchIcon {
  margin-bottom: -6px;

  button {
    margin-left: 0;
  }
}

.searchFlyoutContentWrapper {
  position: absolute;
  top: 100% !important;
  width: 100%;
  max-width: 1280px;
  padding: theme('space.5');
  z-index: 1;
  background-color: rgba(242, 242, 242, 0.94);
  max-height: 62vh;
  overflow-y: auto;
}

.searchOverview {
  display: flex;
  justify-content: space-between;
  margin-bottom: theme('space.3');

  @media (min-width: 1024px) {
    margin-bottom: theme('space.6');
  }
}

.searchTerm {
  display: flex;
  align-items: center;
  gap: theme('space.4');
}

.icon {
  color: theme('colors.gray400');
}

.allResultsLink {
  display: flex;
  color: theme('colors.gray400');
  font-size: theme('fontSize.xs');
  text-decoration: none;

  > span {
    margin-right: theme('space.1');
  }

  &:hover {
    color: theme('colors.gray500');
  }
}

.showMoreToggle {
  color: theme('colors.gray400');
  font-size: theme('fontSize.xs');
  font-weight: normal;

  &:hover {
    color: theme('colors.gray500');
  }
}

.categorySuggestionSection, .productSuggestionSection {
  flex-basis: 50%;
}

.categorySuggestionSection {
  padding-top: theme('space.3');
  padding-bottom: theme('space.3');
  border-top: 1px solid theme('colors.gray100');
  border-bottom: 1px solid theme('colors.gray100');

  @media (min-width: 1024px) {
    order: 1;
    padding: 0;
    border: 0;
  }
}

.flyoutSection {
  width: 100%;

  @media (min-width: 1024px) {
    max-width: calc(25% - 10px);

    &:last-child {
      max-width: 50%;
    }
  }
}

.flyoutSectionHeader {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding-bottom: theme('space.3');
}

.topSellerSection {
  :global .relative {
    margin-top: 0;
    padding-bottom: theme('space.5');

    .slider__container,
    .swiper {
      padding-bottom: 0 !important;

      .swiper-button-prev,
      .swiper-button-next {
        top: calc(50% - 65px);
      }
    }

    div[class^="product-box_productBoxInfo"] {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
        max-width: 100%;

        div[class^="product-box_productBoxInfoPrice"] {
          flex-direction: row;
          font-size: 16px;
        }

        div[class^="product-box_productBoxInfoArtNum"] {
          display: none;
        }
      }
    }
  }
}
