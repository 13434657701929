.wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: theme('colors.white');
  box-shadow: 0 0 10px rgba(15, 32, 47, 0.5);
  transition: all 0.2s ease-in-out;
  z-index: 100;
}

.bottomBarModalBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(15, 32, 47, 0.25);
}

.bottomBarModal {
  position: absolute;
  left: 0;
  bottom: -100%;
  width: 100%;
  max-height: 80vh;
  background-color: theme('colors.gray50');
  height: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &.showModal {
    bottom: 100%;
    height: 80vh;
    opacity: 1;
  }
}

.iconWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: theme('space.3');
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  cursor: pointer;

  &.isActive {
    background-color: theme('colors.primary');

    img {
      filter: brightness(0) invert(1);
    }
  }

  &.enGermanyIsActive {
    background-color: theme('colors.enGermanyPrimary');
  }

  &.toggleModal {
    .toggleClose,
    img {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.2s ease-in-out;
      pointer-events: none;
    }

    .toggleClose {
      color: theme('colors.white');
      opacity: 0;
      z-index: 0;
    }

    &.isActive {
      img {
        z-index: 0;
        opacity: 0;
      }

      .toggleClose {
        opacity: 1;
        z-index: 2;
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }
}

.amountBadge {
  position: absolute;
  top: 6px;
  right: calc(50% - 15px);
  transform: translateX(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  color: theme('colors.white');
  background-color: theme('colors.primaryLight');
  z-index: 1;

  &.enGermanyAmountBadge {
    background-color: theme('colors.enGermanyPrimaryLight');
  }
}

.icon {
  height: 30px;
  max-width: 30px;
}


.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}