.wrap {
  background: theme('colors.primary');
  padding: 16px;
  color: theme('colors.white');
}

.title {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 8px;
  border-left: 1px solid #fff;

  img {
    margin-right: theme('space.2');
  }
}

.amountBubble {
  background-color: theme('colors.gray100');
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 29px;
  font-size: 13px;
  vertical-align: middle;
  text-align: center;
  margin: 0 8px;
}

.content {
  display: flex;
  align-items: center;
}

.sticky {
  z-index: 999;
}
