.flyoutCartOverlay {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1049;
  opacity: 0;
  transition: right 0ms ease-in-out 300ms;

  &.isFlyoutCartOverlayOpen {
    transition: opacity 300ms ease-in-out;
    right: 0;
    opacity: 1;
  }
}

.flyoutCart {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  max-width: 320px;
  height: 100vh;
  font-size: 14px;
  z-index: 1050;
  padding: theme('space.4');
  background-color: theme('colors.white');
  transition: right 300ms ease-in-out;

  &.isFlyoutCartOpen {
    right: 0;
  }

  & * {
    max-width: 100%;
  }
}

.flyoutCartHeaderWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 18px;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }

  strong {
    margin-right: theme('space.2');
  }
}

.flyoutCartHeader {
  display: flex;
  align-items: center;
}

.flyoutCartHeaderPrice {
  font-size: inherit !important;
  font-weight: normal;
  margin: 0 !important;
  padding-left: theme('space.1');
}

.flyoutCartBody {
  flex-grow: 1;
  max-width: calc(100% + 12px);
  width: calc(100% + 12px);
  overflow: auto;
}

.flyoutCartFooter {
  flex: 0 0 auto;
  position: relative;
  max-height: 266px;

  &.flyoutCartFooterHigher {
    max-height: 310px;
  }
}

.flyoutCartSumSection {
  padding: theme('space.5') 0;
  border-top: 1px solid theme('colors.lightGrey');
}

.flyoutCartSumSectionPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.flyoutCartSavedPriceSection {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: theme('colors.success');

  .savedPrice {
    font-size: 14px;
    margin: 0;
    color: theme('colors.success');
  }
}

.flyoutCartVoucherSection {
  width: 100%;
  transform: translateY(120%);
  height: 0;
  opacity: 0;
}

.voucherSectionShown {
  transform: translateY(0);
  height: auto;
  opacity: 1;
}

.flyoutCartButtons {
  position: relative;
  z-index: 1;
}

.buttonsSectionHidden {
  transform: translateY(120%);
}

.flyoutCartButtons,
.flyoutCartVoucherSection {
  transition: all 300ms ease-in-out;
  background-color: theme('colors.white');

  button {
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }

  input {
    max-height: 45px;
  }
}

.primaryBtn {
  margin-top: theme('space.3');

  &:hover {
    color: inherit;
    background-color: inherit;
    opacity: 0.6;
  }
}

.voucherApplyWrapper {
  position: relative;
  margin-top: theme('space.3');
  display: flex;
  justify-content: space-between;
}

.pinWrapper, .applyButton {
  flex: 1 1;
  transition: flex .4s ease, margin .4s ease;
}

.pinWrapper {
  overflow: hidden;
  margin-right: theme('space.2');

  &:not(.active) {
    flex-grow: 0;
    margin: 0;
  }
}

.voucherHint {
  position: absolute;
  overflow: hidden;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.hint {
  padding: theme('spacing.3');
  color: theme('colors.white');
  background-color: theme('colors.success');

  &.hasError {
    background-color: theme('colors.orange');
  }
}

.spinnerWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(165, 172, 177, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
