.tooltip {
  position: absolute;
  top: calc(100% + 10px);
  right: -3px;
  color: #555;
  background: #fff;
  z-index: 30000;
  max-height: 100px;

  font-size: 12px;
  max-width: 280px;
  width: max-content;
  border: 1px solid #ccc;
  box-shadow: 0 0 4px #9a9a9a;
  display: flex;
  flex-direction: column;
  column-gap: 16px;
}
.scrollContainer {
  padding: 8px 40px 8px 20px;
  overflow: scroll;
  height: 100%;
}
.tooltip:after {
  content: '';
  border-width: 10px 8px 10px 8px;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #fff;
  bottom: 100%;
  right: 8px;
  position: absolute;
}
.tooltip:before {
  content: '';
  border-width: 10px 8px 10px 8px;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #ccc;
  bottom: calc(100% + 2px);
  right: 8px;
  position: absolute;
}

.closeButton {
  height: 28px;
  width: 28px;
  position: absolute;
  right: 2px;
  top: 0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeIcon {
  height: 24px;
  width: 24px;
  position: relative;
}
.closeIcon:before {
  content: '';
  position: absolute;
  height: 12px;
  background: #888;
  width: 1px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.closeIcon:after {
  content: '';
  height: 12px;
  width: 1px;
  position: absolute;
  left: 50%;
  background: #888;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
