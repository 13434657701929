.listElement {
  display: inline-block;
  color: theme('colors.gray500');
  font-size: 11px;

  &:not(:last-child):after {
    content: '>';
    padding: 0 6px 0 4px;
  }
}

.active {
  font-size: 11px;
  color: theme('colors.primary');
}
