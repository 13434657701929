.countdown {
  display: flex;
  justify-content: center;
  color: inherit;
  stroke: currentColor;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 theme('space.2');
  border-radius: 3px;
}

.countdownClockBox {
  position: relative;
  margin-right: theme('space.1');
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--countdownTextColor);

  &:first-of-type {
    margin-right: theme('space.2');
  }

  &:last-of-type {
    margin-right: 0;
  }
}
