.acceptTosWrap {
  display: flex;
  column-gap: 10px;
}

.headline {
  font-size: 25px;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.errorCrossRegister {
  color: theme('colors.danger');
}

.markdownGuidelines {
  margin: 0;

  & > * {
    margin: 0;
  }
}

.infoModalContent {
  & > * {
    color: theme('colors.fontColor');
    font-size: 14px;
    margin: inherit !important;
  }
}

.backToLoginLink {
  color: theme('colors.fontColorGray');
}

.errorMsg {
  font-size: 13px;
  font-weight: 400;
  color: theme('colors.danger');
}


.shoppingPreferenceBlock {
  background: #E2E7EB;
  padding: 12px 40px 16px 16px;
  font-size: 14px;
}

.shoppingPreferenceHeadline {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 14px;
}

.shoppingPreferenceShopWrap {
  margin-bottom: 8px;
}
