.countrySelect {
  width: 100%;
  background: theme('colors.inputBackground');
  font-size: 15px;
  border: none;
  padding: theme('space.6') theme('space.10') theme('space.1') theme('space.3');
  -webkit-appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg5QzgxNjBFMTQ0MTExRTdCOThFRjA4NkI3OENDRTY0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg5QzgxNjBGMTQ0MTExRTdCOThFRjA4NkI3OENDRTY0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODlDODE2MEMxNDQxMTFFN0I5OEVGMDg2Qjc4Q0NFNjQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODlDODE2MEQxNDQxMTFFN0I5OEVGMDg2Qjc4Q0NFNjQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4InY9OAAAA1klEQVR42mL8//8/Az0BEwOdwfC3kIUYRYyMjEQbSChNDJkg9QJiD3I0MhKTLdCClBWIL4FCD4j1gPgPKUEKVkAIo4E8qGX/oWySzCPVQmEgfodk4RuoGM0snIxkGQxPpJWFWkD8G4uFIDFNWli4E4tlMLydWAvJSaUUZXwWahhC1aKNFN9Ru2jDF4c7SHIRkVlCmy6pFA1MwWLhJKrmQzRA+5IGC6CoLCWYD7GkUlhtAQK6pNYWLGRkJVAiKQbif+iWUaU+HMh8SJ+ShtoN5eHfLgUIMABKeCDCkCSxEgAAAABJRU5ErkJggg==) no-repeat 100% theme('colors.inputBackground');
}

.countrySelectWithout {
  width: 100%;
  background: theme('colors.inputBackground');
  font-size: 15px;
  border: none;
  padding: theme('space.1') theme('space.10') theme('space.1') theme('space.3');
  -webkit-appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg5QzgxNjBFMTQ0MTExRTdCOThFRjA4NkI3OENDRTY0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg5QzgxNjBGMTQ0MTExRTdCOThFRjA4NkI3OENDRTY0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODlDODE2MEMxNDQxMTFFN0I5OEVGMDg2Qjc4Q0NFNjQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODlDODE2MEQxNDQxMTFFN0I5OEVGMDg2Qjc4Q0NFNjQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4InY9OAAAA1klEQVR42mL8//8/Az0BEwOdwfC3kIUYRYyMjEQbSChNDJkg9QJiD3I0MhKTLdCClBWIL4FCD4j1gPgPKUEKVkAIo4E8qGX/oWySzCPVQmEgfodk4RuoGM0snIxkGQxPpJWFWkD8G4uFIDFNWli4E4tlMLydWAvJSaUUZXwWahhC1aKNFN9Ru2jDF4c7SHIRkVlCmy6pFA1MwWLhJKrmQzRA+5IGC6CoLCWYD7GkUlhtAQK6pNYWLGRkJVAiKQbif+iWUaU+HMh8SJ+ShtoN5eHfLgUIMABKeCDCkCSxEgAAAABJRU5ErkJggg==) no-repeat 100% theme('colors.inputBackground');
}

.showArrows {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg5QzgxNjBFMTQ0MTExRTdCOThFRjA4NkI3OENDRTY0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg5QzgxNjBGMTQ0MTExRTdCOThFRjA4NkI3OENDRTY0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODlDODE2MEMxNDQxMTFFN0I5OEVGMDg2Qjc4Q0NFNjQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODlDODE2MEQxNDQxMTFFN0I5OEVGMDg2Qjc4Q0NFNjQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4InY9OAAAA1klEQVR42mL8//8/Az0BEwOdwfC3kIUYRYyMjEQbSChNDJkg9QJiD3I0MhKTLdCClBWIL4FCD4j1gPgPKUEKVkAIo4E8qGX/oWySzCPVQmEgfodk4RuoGM0snIxkGQxPpJWFWkD8G4uFIDFNWli4E4tlMLydWAvJSaUUZXwWahhC1aKNFN9Ru2jDF4c7SHIRkVlCmy6pFA1MwWLhJKrmQzRA+5IGC6CoLCWYD7GkUlhtAQK6pNYWLGRkJVAiKQbif+iWUaU+HMh8SJ+ShtoN5eHfLgUIMABKeCDCkCSxEgAAAABJRU5ErkJggg==) no-repeat 100% !important;
}

.countrySelectWithout:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.countrySelect:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.selectWrap {
  position: relative;
}

.placeholder {
  color: theme('colors.fontColor');
  position: absolute;
  top: 50%;
  left: 12px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  font-size: 11px;
  font-weight: 500;
  transform: translate(0, calc(-50% - 10px));
}

.errorMessage {
  color: theme('colors.danger');
  font-size: 13px;
  font-weight: 400;
}

.statusWrap {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  transform: translate(0, -50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: theme('space.6');
  top: 50%;
}

.errorIcon, .successIcon {
  width: 20px;
  height: 20px;
  position: relative;
}

.errorIcon:before,
.errorIcon:after {
  content: '';
  height: 20px;
  width: 3px;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(0, 0);
  background: theme('colors.danger');
  transform-origin: center center;
}

.errorIcon:before {
  transform: rotate(45deg);
}

.errorIcon:after {
  transform: rotate(-45deg);
}

.successIcon:before,
.successIcon:after {
  position: absolute;
  left: 0;
  background-color: theme('colors.success');
  content: '';
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.successIcon:before {
  top: 50%;
  height: 50%;
  width: 3px;
}

.successIcon:after {
  bottom: 0;
  height: 3px;
  width: 100%;
}
